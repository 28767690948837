//PROCESSING LIST
export function processingListHasError(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function processingListLoading(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function processing(state = null, action) {
    switch (action.type) {
        case 'PROCESSING_LIST_SUCCESS':
            return action.processing;

        default:
            return state;
    }
}

export function processingQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'PROCESSING_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

//PROCESSING SUBMISSION
export function processingResubmitHasError(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_RESUBMIT_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function processingResubmitLoading(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_RESUBMIT_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function processingResubmitLoadingWithId(state={}, action) {
    switch (action.type) {
        case 'PROCESSING_RESUBMIT_LOADING_W_ID':
            let processingId = action.processingId
            let isLoading = action.isLoading
            let newState = {...state}
            if(processingId in newState) {
                if(!isLoading) {
                    delete newState[processingId]
                }
            } else {
                if(isLoading) {
                    newState[processingId] = isLoading
                }
            }
            return newState
        default:
            return state;
    }
}

export function processingResubmitSuccess(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_RESUBMIT_SUCCESS':
            return action.isSuccessful;

        default:
            return state;
    }
}

//PROCESSING LMS SUBMISSION
export function processingLMSResubmitHasError(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_LMS_RESUBMIT_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function processingLMSResubmitLoading(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_LMS_RESUBMIT_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function processingLMSResubmitLoadingWithId(state={}, action) {
    switch (action.type) {
        case 'PROCESSING_LMS_RESUBMIT_LOADING_W_ID':
            let processingId = action.processingId
            let isLoading = action.isLoading
            let newState = {...state}
            if(processingId in newState) {
                if(!isLoading) {
                    delete newState[processingId]
                }
            } else {
                if(isLoading) {
                    newState[processingId] = isLoading
                }
            }
            return newState
        default:
            return state;
    }
}

export function processingLMSResubmitSuccess(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_LMS_RESUBMIT_SUCCESS':
            return action.isSuccessful;

        default:
            return state;
    }
}

//PROCESSING CLUSTER SUBMISSION
export function processingClusterResubmitHasError(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_CLUSTER_RESUBMIT_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function processingClusterResubmitLoading(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_CLUSTER_RESUBMIT_LOADING':
            return action.isLoading;
        default:
            return state;
    }
}

export function processingClusterResubmitLoadingWithId(state={}, action) {
    switch (action.type) {
        case 'PROCESSING_CLUSTER_RESUBMIT_LOADING_W_ID':
            let processingId = action.processingId
            let isLoading = action.isLoading
            let newState = {...state}
            if(processingId in newState) {
                if(!isLoading) {
                    delete newState[processingId]
                }
            } else {
                if(isLoading) {
                    newState[processingId] = isLoading
                }
            }
            return newState
        default:
            return state;
    }
}

export function processingClusterResubmitSuccess(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_CLUSTER_RESUBMIT_SUCCESS':
            return action.isSuccessful;

        default:
            return state;
    }
}

//CALIBRATION PARAMS
export function paramsListHasError(state = false, action) {
    switch (action.type) {
        case 'PARAMS_LIST_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function paramsListLoading(state = false, action) {
    switch (action.type) {
        case 'PARAMS_LIST_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function params(state = null, action) {
    switch (action.type) {
        case 'PARAMS_LIST_SUCCESS':
            return action.params;

        default:
            return state;
    }
}

export function paramsQueryParams(state = {
    page: 1, pageSize: 10, total: -1//, filter: 'All'
}, action) {
    switch (action.type) {
        case 'PARAMS_QUERY_PARAMS':
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

//CALIBRATION PARAMS - CREATE
export function paramsUpdateHasError(state = false, action) {
    switch (action.type) {
        case 'PARAMS_UPDATE_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function paramsUpdateLoading(state = false, action) {
    switch (action.type) {
        case 'PARAMS_UPDATE_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function paramsUpdateSuccess(state = null, action) {
    switch (action.type) {
        case 'PARAMS_UPDATE_SUCCESS':
            return action.params;

        default:
            return state;
    }
}

export function processingUpdateHasError(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_UPDATE_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function processingUpdateLoading(state = false, action) {
    switch (action.type) {
        case 'PROCESSING_UPDATE_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function processingUpdateSuccess(state = null, action) {
    switch (action.type) {
        case 'PROCESSING_UPDATE_SUCCESS':
            return action.processing;

        default:
            return state;
    }
}

export function calibrationDownloadLoading(state = false, action) {
    switch (action.type) {
        case 'CALIBRATION_DOWNLOAD_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

//DOWNLOAD AUTH
export function downloadAuthHasError(state = false, action) {
    switch (action.type) {
        case 'DOWNLOAD_AUTH_HAS_ERROR':
            return action.hasError;

        default:
            return state;
    }
}

export function downloadAuthSuccess(state = null, action) {
    switch (action.type) {
        case 'DOWNLOAD_AUTH_SUCCESS':
            return action.url;

        default:
            return state;
    }
}

export function downloadAuthLoading(state = false, action) {
    switch (action.type) {
        case 'DOWNLOAD_AUTH_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

// Fetch kmz file list
export const fetchKmzFilePresignedUrlListLoading = (state=false, action) => {
    switch (action.type) {
        case 'FETCH_KMZ_FILE_URL_LIST_LOADING':
            return action.isLoading;
        default:
            return state;
    }
}

export const fetchKmzFilePresignedUrlListSuccess = (state = null, action) => {
    switch (action.type) {
        case 'FETCH_KMZ_FILE_URL_LIST_SUCCESS':
            return action.fileList;
        default:
            return state;
    }
}

export const fetchKmzFilePresignedUrlListHasError = (state = false, action) => {
    switch (action.type) {
        case 'FETCH_KMZ_FILE_URL_LIST_HAS_ERROR':
            return action.hasError;
        default:
            return state;
    }
}