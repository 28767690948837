import React, { useState, useEffect, useRef, BaseSyntheticEvent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { 
    Grid, Button, Dialog, FormControl, Input, LinearProgress,
    Select, MenuItem, FormHelperText, DialogTitle, DialogContent,
    DialogActions, IconButton, CircularProgress, 
} from '@material-ui/core'
import UploadStages from './UploadStages'
import NicerTooltip from '../common/NicerTooltip';
import CloudUpload from '@material-ui/icons/CloudUpload'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled'
import Cancel from '@material-ui/icons/Cancel'
import {
    updateUploadStatus, handleUploadMetadata, addUploadMetadataList, addUploadTaskList,
    handleUpdateUploadStatusToDB, addUploadOngoingTask, updateUploadOngoingTaskStatus,
    updateUploadOngoingTaskProgress, handleUploadBlob, completeUploadOngoingTask,
    withdrawAllUploadOngingTasks, updateGlobalUploadProgress, handleFetchUploadProgress,
    handleCheckUploadProgress, filterRemainingUploadTask, cancelUploadTask,
    handleFetchUploadRecord, handleAccessCurrentReduxAuthState,
    handleAccessCurrentReduxUploaderState, clearUploaderStore, handleUpdateUploadHeartbeat,
    handleCheckConcurrentUpload
} from './UploaderActions'
import { 
    UploaderStore, FolderValidationSummary, FetchSensorEquipmentListResponse,
    UploadTask, BlobToUpload, FetchUploadProgressResponse, UploadMeta,
    CheckUploadProgressErrorResponse, CheckUploadProgressResponse, FetchUploadRecordResponse, CheckConcurrentUploadResponse, FetchFlightByFlightIdResponse
} from './UploaderTypes'
import FileSelectionDialog from '../common/FileSelectionDialog'
import FileValidationDialog from '../common/FileValidationDialog'
import GPSCheckDialog from '../common/GPSCheckDialog'
import ConfirmationDialog from '../common/ConfirmationDialog'
import CancellationDialog from '../common/CancellationDialog';
import FolderValidationHelper, { FileRuleSummary, FMSLogMismatchProjectAlertMessage, FMSLogMismatchTimeAlertMessage } from './FolderValidationHelper'
import MetadataGenerationHelper from './MetadataGenerationHelper'
import UploadResume from './UploadResume'
import UploadReplace from './UploadReplace'
import { renewToken } from '../auth/AuthenticationActions'
import UploadConfirmationDialog from '../common/UploadConfirmationDialog'
import { formatBytes } from './UploadProgress'
// import CompressionWorker from './CompressionWorker'
import ZstdCompressionWorker from './ZstdCompressionWorker'
import HeartbeatWorker from './HeartbeatWorker'
import Fingerprint2 from 'fingerprintjs2'
import Config from '../app/Config.json'
import './commonStyles.css'
import './uploaderStyles.css'

//////////////////////////////////
//           Constants          //
//////////////////////////////////
// These status are more than possible values in MongoDB. 
// Because some are only used in client side to present different UI.
export const VALID_STATUS = {
    // INIT: 'INIT',
    UPLOAD_INIT: 'UPLOAD_INIT',     // Init status. Before get status response from DB.
    // CREATING_UPLOAD_ENTRY: 'CREATING_UPLOAD_ENTRY',
    READY_FOR_UPLOAD: "READY_FOR_UPLOAD",           
    VALIDATING_SOURCE: "VALIDATING_SOURCE",
    VALIDATING_SOURCE_FAILED: 'VALIDATING_SOURCE_FAILED',
    GENERATING_METADATA_N_UPLOAD_TASK: 'GENERATING_METADATA_N_UPLOAD_TASK',
    GENERATING_METADATA_N_UPLOAD_TASK_FAILED: 'GENERATING_METADATA_N_UPLOAD_TASK_FAILED',
    CHECKING_CONCURRENT_UPLOAD: 'CHECKING_CONCURRENT_UPLOAD',
    UPLOAD_QUEUED: 'UPLOAD_QUEUED',
    UPLOADING: "UPLOADING",
    UPLOAD_FAILED: "UPLOAD_FAILED",
    UPLOAD_CANCELLED: "UPLOAD_CANCELLED",
    UPDATE_CANCELLED: 'UPDATE_CANCELLED',
    UPLOAD_PAUSED: "UPLOAD_PAUSED",
    UPLOAD_STALLED: "UPLOAD_STALLED",
    GENERATING_METADATA_N_CHECK_PROGRESS: 'GENERATING_METADATA_N_CHECK_PROGRESS',   // resume from another session
    GENERATING_METADATA_N_CHECK_PROGRESS_FAILED: 'GENERATING_METADATA_N_CHECK_PROGRESS_FAILED',
    UPLOAD_INTEGRITY_CHECK: 'UPLOAD_INTEGRITY_CHECK',   // check if there is any missing file
    UPLOAD_COMPLETE: "UPLOAD_COMPLETE",
    VALIDATING_UPLOAD: "VALIDATING_UPLOAD",         // wait for server side response
    VALIDATING_UPLOAD_FAILED: "VALIDATING_UPLOAD_FAILED",
    DATA_READY: "DATA_READY",
}

const VALID_FSM_EVENT_TYPE = {
    FOLDER_SELECTED: 'FOLDER_SELECTED',
    VALIDATION_SUCCEED: 'VALIDATION_SUCCEED',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED: 'GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED',
    GENERATE_METADATA_N_UPLOAD_TASK_FAILED: 'GENERATE_METADATA_N_UPLOAD_TASK_FAILED',
    CONCURRENT_UPLOAD_DETECTED: 'CONCURRENT_UPLOAD_DETECTED',
    CONCURRENT_UPLOAD_NOT_DETECTED: 'CONCURRENT_UPLOAD_NOT_DETECTED',
    CHECK_CONCURRENT_UPLOAD_FAILED: 'CHECK_CONCURRENT_UPLOAD_FAILED',
    QUIT_UPLOAD_QUEUE: 'QUIT_UPLOAD_QUEUE',
    PAUSE_UPLOAD: 'PAUSE_UPLOAD',
    CHECK_PROGRESS_SUCCEED: 'CHECK_PROGRESS_SUCCEED',
    CHECK_PROGRESS_FAILED: 'CHECK_PROGRESS_FAILED',
    RESUME_UPLOAD: 'RESUME_UPLOAD',
    CANCEL_UPLOAD: 'CANCEL_UPLOAD',
    CANCEL_UPDATE: 'CANCEL_UPDATE',
    UPLOAD_ALL_TASKS_SUCCEED: 'UPLOAD_ALL_TASKS_SUCCEED',
    UPLOAD_FAILED: 'UPLOAD_FAILED',
    UPLOAD_INTEGRITY_CHECK_SUCCEED: 'UPLOAD_INTEGRITY_CHECK_SUCCEED',
    UPLOAD_INTEGRITY_CHECK_FAILED: 'UPLOAD_INTEGRITY_CHECK_FAILED',
    UPLOAD_INTEGRITY_CHECK_MISSING_FILE_DETECTED: 'UPLOAD_INTEGRITY_CHECK_MISSING_FILE_DETECTED',
    UPLOAD_INTEGRITY_CHECK_REACH_MAX_RETRY: 'UPLOAD_INTEGRITY_CHECK_REACH_MAX_RETRY',
    WAIT_FOR_BACKEND_UPLOAD_VALIDATITON: 'WAIT_FOR_BACKEND_UPLOAD_VALIDATITON',
    BACKEND_REBUILD_SUCCEED: 'BACKEND_REBUILD_SUCCEED',
    BACKEND_REBUILD_FAILED: 'BACKEND_REBUILD_FAILED',
}

export const VALID_ONGOING_TASK_STATUS = {
    READING: 'READING',
    COMPRESSING: 'COMPRESSING',                     // equivalent to READING_COMPLETE
    WAITING_FOR_UPLOAD: 'WAITING_FOR_UPLOAD',       // equivalent to COMPRESSION_COMPLETE
    UPLOADING: 'UPLOADING',
    COMPLETE: 'COMPLETE'                            // ready for moving to 'completedTasks'
}

// Maximum number of concurrent web workers
const MAX_CONCURRENT_WEB_WORKER = 4           
// Maximum length of store blob in the queue, which is actually the max number of ongoing tasks that are in 'WAITING_FOR_UPLOAD'
const MAX_UPLOAD_WAITING_QUEUE_DEPTH = 4
// Maxium number of concurrent uploading. Now it is only allowed a single thread to upload concurrently.
const MAX_CONCURRENT_UPLOAD = 1
// Maximum number of concurrent reading tasks
const MAX_CONCURRENT_READING_CHUNK = 2
const FETCH_UPLOAD_VALIDATION_RESULT_INTERVAL = 5 * 1000        // unit: ms
const FETCH_UPLOAD_VALIDATION_RESULT_SLOW_INTERVAL = 20 * 1000  // unit: ms
const CHECK_UPLOAD_QUEUE_POSITION_INTERVAL = 10 * 1000          // unit : ms
const MAX_UPLOAD_INTEGRITY_CHECK_RETRY = 2

const BASE_URI = Config.BASE_URI as string

const HEARTBEAT_INTERVAL = 10 * 1000         // unit: ms
// const LOCAL_STORAGE_HEARTBEAT_TIMESTAMP = 'LiDARnetics.heartbeat'
// const HEARTBEAT_INTERVAL_MARGIN = HEARTBEAT_INTERVAL * 3
// const MAX_HEARTBEAT_FORCE_UPDATE_INTERVAL = HEARTBEAT_INTERVAL * 2
const TOKEN_MIN_REMAINING_TIME = 3600       // unit: s, if less than an hour on token then renew

export class WebWorker {
    constructor(worker: any) {
        const code = worker.toString();
        const blob = new Blob(["(" + code + ")()"]);
        return new Worker(URL.createObjectURL(blob));
    }
}

interface WebWorkerLookup {
    [key: string]: Worker
}

interface UploadCancelTokenSourceLookup {
    [key: string]: CancelTokenSource
}

// Have to use a global var to store all cancel tokens.
// Using useState to store a cancelToken lookup will end up with the out of memory issue when uploading a large file with thousands of chunks
// Probably use useEffect to trigger further steps like state transition maybe a solution,
// But it would make the logic mess and make codes hard to maintain
var globalCancelTokenLookup: UploadCancelTokenSourceLookup = {}

// Likewise, have to use a global blob queue to avoid running into race condition issue
var globalBlobToUploadQueue: Array<any> = []

// Declare it to be a global to terminate web workers when unmounting components
var concurrentWebWorkerLookup: WebWorkerLookup = {}
var idleWebWorkerLookup: WebWorkerLookup = {}

//////////////////////////////////
//           Component          //
//////////////////////////////////
const UploadPanel = (props: UploadPanelFromRedux) => {
    // Props from Redux mapStateToProps
    const { authToken, user, uploaderStore } = props
    // Props from Redux mapDispatchToProps
    const { 
        updateUploadStatus, handleUploadMetadata, addUploadMetadataList, addUploadTaskList,
        handleUpdateUploadStatusToDB, addUploadOngoingTask, updateUploadOngoingTaskStatus,
        updateUploadOngoingTaskProgress, handleUploadBlob, completeUploadOngoingTask,
        withdrawAllUploadOngingTasks, updateGlobalUploadProgress, handleFetchUploadProgress,
        handleCheckUploadProgress, filterRemainingUploadTask, cancelUploadTask,
        handleFetchUploadRecord, handleAccessCurrentReduxAuthState,
        handleAccessCurrentReduxUploaderState, clearUploaderStore, renewToken,
        handleUpdateUploadHeartbeat, handleCheckConcurrentUpload
    } = props

    const hiddenUploadFolderInput = useRef<HTMLInputElement>(null)
    // Type File couldn't be serialized. Using Redux action/reduce will throw state mutation error
    const [fileList, setFileList] = useState<FileList | Array<File> | null>(null)
    // Buttons
    const [isSelectFolderBtnEnabled, setIsSelectFolderBtnEnabled] = useState<Boolean>(true)
    const [isPauseResumeBtnEnabled, setIsPauseResumeBtnEnabled] = useState<Boolean>(false)
    const [isPauseBtnDisplay, setIsPauseBtnDisplay] = useState<Boolean>(true)           // display RESUME btn when it is false
    const [isCancelBtnEnabled, setIsCancelBtnEnabled] = useState<Boolean>(false)
    // Dialogs
    const [isFileSelectionDialogOpen, setIsFileSelectionDialogOpen] = useState<boolean>(false)
    const [isFolderValidationDialogOpen, setIsFolderValidationDialogOpen] = useState<boolean>(false)
    // const [isUploadCancelDialogOpen, setIsUploadCancelDialogOpen] = useState<boolean>(false)
    const [isGPSCheckDialogOpen, setIsGPSCheckDialogOpen] = useState<boolean>(false)
    const [isGPSCheckDialogCancelled, setIsGPSCheckDialogCancelled] = useState<boolean>(false)
    const [isMissingFlightplanCrossStrip, setIsMissingFlightplanCrossStrip] = useState<boolean>(false)
    const [isMissingFlightPlanCSDialogOpen, setIsMissingFlightPlanCSDialogOpen] = useState<boolean>(false)
    const [isUploadResumeBtnEnabled, setIsUploadResumeBtnEnabled] = useState<boolean>(false)    // the button on the UploadResume Component
    const [isSyncStatusDialogOpen, setIsSyncStatusDialogOpen] = useState<boolean>(false)
    const [isUploadCancelDialogOpen, setIsUploadCancelDialogOpen] = useState<boolean>(false)
    const [isUpdateExistingDataDialogOpen, setIsUpdateExistingDataDialogOpen] = useState<boolean>(false)
    // const [fileMetadata, setFileMetadata] = useState<any>()
    const [folderValidationSummary, setFolderValidationSummary] = useState<FolderValidationSummary>()
    const [isGlobalDisableCompression, setIsGlobalDisableCompression] = useState<boolean>(false)
    const [missingGPSStartFilenameList, setMissingGPSStartFilenameList] = useState<Array<string>>()
    // The type Worker couldn't be serialized. Using Redux action/reduce will throw state mutation error
    // const [concurrentWebWorkerLookup, setConcurrentWebWorkerLookup] = useState<WebWorkerLookup>({})
    const [heartbeatWebWorker, setHeartbeatWebWorker] = useState<Worker>()
    const [uploadIntegrityCheckCounter, setUploadIntegrityCheckCounter] = useState<number>(0)
    const [browserFingerprint, setBrowserFingerprint] = useState<string>('')
    const [uploadQueueIndex, setUploadQueueIndex] = useState<number>(-1)
    const [isUploadQueuingDialogOpen, setIsUploadQueuingDialogOpen] = useState<boolean>(false)
    // const [chunkReadCounter, setChunkReadCounter] = useState<number>(0)         // mainly used to trigger useEffect
    const [isFmsLogMismatchProjectAlertDialogOpen, setIsFmsLogMismatchProjectAlertDialogOpen] = useState<boolean>(false)
    const [fmsLogFilenameListWithNoProjectId, setFmsLogFilenameListWithNoProjectId] = useState<Array<string>>([])
    const [fmsLogFilenameListWithUnexpectedProjectId, setFmsLogFilenameListWithUnexpectedProjectId] = useState<Array<string>>([])
    const [missingProjectIdList, setMissingProjectIdList] = useState<Array<string>>([])
    const [isFmsLogMismatchTimeAlertDialogOpen, setIsFmsLogMismatchTimeAlertDialogOpen] = useState<boolean>(false)
    const [fmsLogFilenameList, setFmsLogFilenameList] = useState<Array<string>>([])
    const [isBlankFileAlertDialogOpen, setIsBlankFileAlertDialogOpen] = useState<boolean>(false)
    const [blankFileList, setBlankFileList] = useState<Array<string>>([])
    const [lazFilenamelistWithoutRGBN, setLazFilenamelistWithoutRGBN] = useState<Array<string>>([])
    const [isLazRgbnAlertDialogOpen, setIsLazRgbnAlertDialogOpen] = useState<boolean>(false)
    const [lazFilenameListWithInvalidHeader, setLazFilenameListWithInvalidHeader] = useState<Array<string>>([])
    const [isLazHeaderAlertDialogOpen, setIsLazHeaderAlertDialogOpen] = useState<boolean>(false)

    // TSX doesn't support webkitdirectory. Have to set here 
    useEffect(() => {
        if (hiddenUploadFolderInput.current !== null) {
            hiddenUploadFolderInput.current.setAttribute("webkitdirectory", "");
            hiddenUploadFolderInput.current.setAttribute("multiple", "");
            hiddenUploadFolderInput.current.setAttribute("directory", "");
          }
    }, [hiddenUploadFolderInput, isSelectFolderBtnEnabled])

    useEffect(() => {
        Fingerprint2.getPromise().then((components) => {
            var values = components.map(function (component) { return component.value })
            values.push(user)           // helps prevent duplicate fingerprint
            let fingerprint = Fingerprint2.x64hash128(values.join(''), 31)
            setBrowserFingerprint(fingerprint)
            // console.log(`fingerprint: ${fingerprint}`)
        })
        
        return () => {
            setHeartbeatWebWorker(prevState => {
                prevState && (prevState as Worker).postMessage({ msg: 'terminate' })
                console.log(`Heartbeat webworker terminated`)
                return undefined
            })

            console.log(`Force to Terminate All Concurrent Workers: ${Object.keys(concurrentWebWorkerLookup).length}`)
            Object.keys(concurrentWebWorkerLookup).forEach((workerId, index) => {
                concurrentWebWorkerLookup[workerId].postMessage(['pause'])
                console.log(`  Worker ${index + 1}: ${workerId}`)
            })

            const currentUploaderStore = handleAccessCurrentReduxUploaderState()
            let concurrentUploadingOngoingTaskKeyList: Array<string> = Object.keys(currentUploaderStore.ongoingTaskLookup).filter(key => 
                currentUploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.UPLOADING
            )
            console.log(`Force to Terminate All Concurrent Upload: ${Object.keys(concurrentUploadingOngoingTaskKeyList).length}`)
            concurrentUploadingOngoingTaskKeyList.forEach((key, index) => {
                console.log(`  Upload ${index + 1}: ${key}`)
            })
            Object.entries(globalCancelTokenLookup).forEach(([fullPath, cancelTokenSource]) => {
                cancelTokenSource.cancel('Operation cancelled by the user')
            });
            resetAllNonBinary()
            // clearUploaderStore()
        }
    }, [])

    useEffect(() => {
        console.log(`Status changed to: ${uploaderStore.status}`)
        // var checkUploadQueueIntervalId: number = -1
        switch(uploaderStore.status) {
            case VALID_STATUS.UPLOAD_INIT:
                // console.log('Status changed to UPLOAD_INIT')
                break
            // case VALID_STATUS.CREATING_UPLOAD_ENTRY: 
            //     console.log('Status changed to CREATING_UPLOAD_ENTRY')
            //     setIsInputFieldEnabled(false)
            //     handleCreateNewUploadEntry(sensorType, {userId, projectId, projectName})
            //     .then(response => {
            //         console.log(`Create a new upload entry: guid: ${response}`)
            //         if(response) {
            //             printLog(`Create a new GUID: ${response}`)
            //             setProcessingId(response)
            //             updateUploadStatus(transition({ status: uploaderStore.status }, { type: VALID_FSM_EVENT_TYPE.CREATE_NEW_UPLOAD_ENTRY_SUCCEED }).status)
            //         } else {
            //             printLog(`Failed to create a new upload entry`)
            //             updateUploadStatus(transition({ status: uploaderStore.status }, { type: VALID_FSM_EVENT_TYPE.CREATE_NEW_UPLOAD_ENTRY_FAILED }).status)
            //         }
            //     })
            //     break
            case VALID_STATUS.VALIDATING_SOURCE: {
                const folderValidationHelperReturn = folderValidation()
                break
            }
            case VALID_STATUS.VALIDATING_SOURCE_FAILED:
                // console.log('Status changed to VALIDATING_SOURCE_FAILED')
                break
            case VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK: {
                generateMetadataNUploadTask()
                // let nextState = transition({ type: VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED })
                let nextState: { status: string }
                if(uploaderStore.uploadMeta) {
                    // setIsSyncStatusDialogOpen(true)
                    // handleUploadMetadata(`${uploaderStore.uploadMeta.flightId}_metadata.json`, axios.CancelToken.source().token)
                    //     .then(response => {
                    //         uploaderStore.uploadMeta && console.log(`Upload Completed: ${uploaderStore.uploadMeta.flightId}_metadata.json`)
                    //         nextState = transition({ type: VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED })
                    //         return handleUpdateUploadStatusToDB(nextState.status)
                    //     })
                    //     // .then(response => {
                    //     //     updateUploadStatus(nextState.status)
                    //     // })
                    //     .catch(error => {
                    //         console.log(`Error: ${error}`)
                    //         nextState = transition({ type: VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_FAILED })
                    //     }).finally(() => {
                    //         updateUploadStatus(nextState.status)
                    //         setIsSyncStatusDialogOpen(false)
                    //     })
                    handleUploadMetadata(`${uploaderStore.uploadMeta.flightId}_metadata.json`, axios.CancelToken.source().token)
                    .then(response => {
                        uploaderStore.uploadMeta && console.log(`Upload Completed: ${uploaderStore.uploadMeta.flightId}_metadata.json`)
                        nextState = transition({ type: VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED })
                        updateUploadStatus(nextState.status)
                    })
                    .catch(error => {
                        console.log(`${error}`)
                        nextState = transition({ type: VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_FAILED })
                        updateUploadStatus(nextState.status)
                    })
                }
                break
            }
            case VALID_STATUS.CHECKING_CONCURRENT_UPLOAD: {
                let nextState: { status: string };
                handleCheckConcurrentUpload(browserFingerprint)
                .then(response => {
                    // if(response) {
                    if(Object.keys(response).length) {
                        if((response as CheckConcurrentUploadResponse).concurrentQueueDepth == 0
                                || (response as CheckConcurrentUploadResponse).queueIndex == 0) {
                            nextState = transition({ type: VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_NOT_DETECTED })
                        } else {
                            nextState = transition({ type: VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_DETECTED })
                        }
                    } else {
                        nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED })
                    }
                    // console.log(`handleCheckConcurrentUpload: ${JSON.stringify(response, null, 4)}`)
                    setIsSyncStatusDialogOpen(true)
                    return handleUpdateUploadStatusToDB(nextState.status)
                })
                .then(response => {
                    updateUploadStatus(nextState.status)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => setIsSyncStatusDialogOpen(false))
                break
            }
            case VALID_STATUS.UPLOAD_QUEUED: {
                var checkUploadQueueIntervalId: number
                if(browserFingerprint && fileList) {
                    handleUpdateUploadHeartbeat(browserFingerprint)
                    .then(response => {
                        return handleCheckConcurrentUpload(browserFingerprint)
                    })
                    .then(response => {
                        if(Object.keys(response).length) {
                            setUploadQueueIndex((response as CheckConcurrentUploadResponse).queueIndex)
                        } else {
                            setUploadQueueIndex(-1)
                            // let nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    checkUploadQueueIntervalId = setCheckUploadQueueTimer(CHECK_UPLOAD_QUEUE_POSITION_INTERVAL)
                }
                break
            }
            case VALID_STATUS.UPLOADING:
                // updateLocalStorageHeartbeatTimestamp()
                setUploadQueueIndex(-1)
                break
            case VALID_STATUS.UPLOAD_PAUSED:
                if(fileList) {
                    withdrawAllUploadOngingTasks()
                    updateGlobalUploadProgress({ globalSpeed: 0 })
                } else {
                    enableResumeDialog()
                }
                break
            case VALID_STATUS.UPLOAD_STALLED:
                if(fileList === null) {
                    enableResumeDialog()
                }
                break
            case VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS: {
                generateMetadataNUploadTask()
                handleCheckUploadProgress()
                    .then(response => {
                        // console.log(`handleCheckUploadProgress: ${JSON.stringify(response, null, 4)}`)
                        if((response as CheckUploadProgressErrorResponse).reason) {             // Failed to pass file consistency check
                            throw Error((response as CheckUploadProgressErrorResponse).reason)
                        } else {
                            const { 
                                remaining_task_key_list: remainingTaskKeyList,
                                completed_file_size: completedFileSize, 
                                total_file_size: totalFileSize,
                                total_file_count: totalFileCount,
                            } = response as CheckUploadProgressResponse
                            const completedFileCount = totalFileCount - remainingTaskKeyList.length
                            updateGlobalUploadProgress({ completedFileSize, totalFileSize, completedFileCount, totalFileCount })
                            console.log('Updating upload task list:')
                            console.log(`    Remaining Upload Tasks Count: ${(response as CheckUploadProgressResponse).remaining_task_key_list.length}`)
                            filterRemainingUploadTask((response as CheckUploadProgressResponse).remaining_task_key_list)
                        }
                    })
                    .then(response => {
                        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_PROGRESS_SUCCEED })
                        // setIsSyncStatusDialogOpen(true) 
                        // handleUpdateUploadStatusToDB(nextState.status)
                        //     .then(response => {
                        //         updateUploadStatus(nextState.status)
                        //     })
                        //     .catch(error => {
                        //         console.log(error)
                        //     })
                        //     .finally(() => setIsSyncStatusDialogOpen(false))
                        updateUploadStatus(nextState.status)
                    })
                    .catch(error => {
                        console.log(error)
                        setFileList(null)
                        // cancelUploadTask()
                        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_PROGRESS_FAILED })
                        updateUploadStatus(nextState.status)
                    })
                break
            }
            case VALID_STATUS.UPLOAD_CANCELLED:
                setFileList(null)
                cancelUploadTask()
                break
            case VALID_STATUS.UPDATE_CANCELLED:
                setFileList(null)
                cancelUploadTask()
                break
            case VALID_STATUS.UPLOAD_INTEGRITY_CHECK: 
                console.log(`Processing upload integrity check... ${uploadIntegrityCheckCounter + 1} Attempt`)
                setUploadIntegrityCheckCounter(prevState => prevState + 1)
                break
            case VALID_STATUS.UPLOAD_COMPLETE:
                let nextState = transition({ type: VALID_FSM_EVENT_TYPE.WAIT_FOR_BACKEND_UPLOAD_VALIDATITON })
                updateUploadStatus(nextState.status)
                break
            case VALID_STATUS.VALIDATING_UPLOAD:
                console.log('Validating and rebuilding uploaded files on servers... It may take a few minutes...')
                break
            case VALID_STATUS.VALIDATING_UPLOAD_FAILED:
                console.log(`Validate upload files FAILED. Please contact the admin to solve the issue.`)
                break
            case VALID_STATUS.DATA_READY:
                fileList && console.log(`Validate upload files SUCCEED.`)
                break
            default: 
                break
        }
        btnStateTransition()
        if(uploaderStore.status === VALID_STATUS.VALIDATING_UPLOAD) {
            var fetchUploadValidationResultInvervalId = setFetchUploadValidationResultTimer(FETCH_UPLOAD_VALIDATION_RESULT_INTERVAL)
        }
        if(uploaderStore.status === VALID_STATUS.VALIDATING_UPLOAD_FAILED) {
            var fetchUploadValidationResultInvervalId = setUploadValidationResultPolling(FETCH_UPLOAD_VALIDATION_RESULT_SLOW_INTERVAL)
        }
        // Update heartbeat while uploading
        if((uploaderStore.status === VALID_STATUS.UPLOADING
                || uploaderStore.status === VALID_STATUS.UPLOAD_QUEUED) && fileList) {
            // explicitly update the heartbeat to add browserFingerprint to DB at the begining
            if (uploaderStore.status === VALID_STATUS.UPLOADING) {
                handleUpdateUploadHeartbeat(browserFingerprint)
                // if(checkUploadQueueIntervalId) {
                //     clearCheckUploadQueueTimer(checkUploadQueueIntervalId)
                //     checkUploadQueueIntervalId = 0
                // }
            }
            let worker = null
            if(heartbeatWebWorker === undefined) {
                console.log('Creating a new heartbeat webworker')
                worker = new WebWorker(HeartbeatWorker) as Worker
                setHeartbeatWebWorker(worker)
                worker.onerror = function (event) {
                    console.log("Heartbeat web worker error", event.message, event.filename, event.lineno, event);
                };
                worker.postMessage({
                    msg: 'start', 
                    url: BASE_URI + `flights/${(uploaderStore.uploadMeta as UploadMeta).flightId}/upload/${(uploaderStore.uploadMeta as UploadMeta).uploadId}/heartbeat`,
                    token: authToken,
                    timeInterval: HEARTBEAT_INTERVAL,
                    browserFingerprint
                })
            }
            // worker = worker || heartbeatWebWorker || null
            // var heartbeatIntervalId = setHeartbeatTimer(HEARTBEAT_INTERVAL, worker)     // unit: ms
            // var printProgressIntervalId = setPrintProgressTimer(PRINT_PROGRESS_INTERVAL)    // unit: ms
        } else if(heartbeatWebWorker) {
            // Duplicated remove logic in case heartbeat web worker failed to be removed when pressing pause and cancel btn
            heartbeatWebWorker.postMessage({ msg: 'terminate' })
            console.log(`Heartbeat webworker terminated`)
            setHeartbeatWebWorker(undefined)
        }

        // Clean up timers
        return () => {
            // if(heartbeatIntervalId) {
            //     clearHeartbeatTimer(heartbeatIntervalId)
            //     heartbeatIntervalId = 0
            // }
            // if(printProgressIntervalId) {
            //     clearPrintProgressTimer(printProgressIntervalId)
            //     printProgressIntervalId = 0
            // }
            if(fetchUploadValidationResultInvervalId) {
                clearFetchUploadValidationResultTimer(fetchUploadValidationResultInvervalId)
                fetchUploadValidationResultInvervalId = 0
            }
            if(checkUploadQueueIntervalId) {
                clearCheckUploadQueueTimer(checkUploadQueueIntervalId)
                checkUploadQueueIntervalId = 0
                console.log(`uploadQueue timer is cleared`)
            }
        }
    }, [uploaderStore.status])

    // The tokens is renewed by PrivateRoute
    useEffect(() => {
        if(([VALID_STATUS.UPLOADING, VALID_STATUS.UPLOAD_QUEUED].indexOf(uploaderStore.status) > -1) && authToken && heartbeatWebWorker) {
            heartbeatWebWorker.postMessage({ 
                msg: 'tokenRenew',
                url: BASE_URI + `flights/${(uploaderStore.uploadMeta as UploadMeta).flightId}/upload/${(uploaderStore.uploadMeta as UploadMeta).uploadId}/heartbeat`,
                token: authToken,
                timeInterval: HEARTBEAT_INTERVAL,
                browserFingerprint: browserFingerprint
            })
        }
    }, [uploaderStore.status, authToken, heartbeatWebWorker])

    // Buttons' onclick callback functions
    const handleSelectFolderBtnOnClick = () => {
        // TODO: reset state here if needed
        // console.log('handleSelectFolderBtnOnClick: ', hiddenUploadFolderInput.current !== null && hiddenUploadFolderInput.current.value, hiddenUploadFolderInput.current !== null && (hiddenUploadFolderInput.current as any).webkitdirectory)
        // Reset hiddenUploadFolderInput if <input> has been clicked. 
        if(hiddenUploadFolderInput.current !== null && hiddenUploadFolderInput.current.value) {
            hiddenUploadFolderInput.current.value = ''
        }
        // hiddenUploadFolderInput.current !== null && hiddenUploadFolderInput.current.click()
        setFolderValidationSummary(undefined)
    }

    const terminateConcurrentWebWorker = () => {
        console.log(`Terminate All Concurrent Workers: ${Object.keys(concurrentWebWorkerLookup).length}`)
        Object.keys(concurrentWebWorkerLookup).forEach((workerId, index) => {
            concurrentWebWorkerLookup[workerId].postMessage(['pause'])
            console.log(`  Worker ${index + 1}: ${workerId}`)
            removeWebWorkerFromLookup(workerId)
        })
    }

    const terminateConcurrentUpload = () => {
        let concurrentUploadingOngoingTaskKeyList: Array<string> = Object.keys(uploaderStore.ongoingTaskLookup).filter(key => 
            uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.UPLOADING
        )
        console.log(`Terminate All Concurrent Upload: ${Object.keys(concurrentUploadingOngoingTaskKeyList).length}`)
        concurrentUploadingOngoingTaskKeyList.forEach((key, index) => {
            console.log(`  Upload ${index + 1}: ${key}`)
        })
        Object.entries(globalCancelTokenLookup).forEach(([fullPath, cancelTokenSource]) => {
            cancelTokenSource.cancel('Operation cancelled by the user')
        });
    }

    const handlePauseBtnOnClick = () => {
        terminateConcurrentWebWorker()
        terminateConcurrentUpload()

        globalBlobToUploadQueue = []
        setUploadIntegrityCheckCounter(0)
        // resetAllNonBinary()

        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.PAUSE_UPLOAD })
        if(heartbeatWebWorker) {
            heartbeatWebWorker.postMessage({ msg: 'terminate' })
            console.log(`Heartbeat webworker terminated`)
            setHeartbeatWebWorker(undefined)
        }
        setIsSyncStatusDialogOpen(true)
        handleUpdateUploadStatusToDB(nextState.status)
            .then(response => {
                updateUploadStatus(nextState.status)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setIsSyncStatusDialogOpen(false))
    }

    const handleResumeBtnOnClick = () => {
        // console.log('Clicked Resume Btn')
        globalCancelTokenLookup = {}
        globalBlobToUploadQueue = []

        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.RESUME_UPLOAD })
        // setIsSyncStatusDialogOpen(true) 
        // handleUpdateUploadStatusToDB(nextState.status)
        //     .then(response => {
        //         updateUploadStatus(nextState.status)
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })
        //     .finally(() => setIsSyncStatusDialogOpen(false))
        updateUploadStatus(nextState.status)
    }

    const handleCancelBtnOnClick = () => {
        setIsUploadCancelDialogOpen(true)
    }

    const handleUploadCancel = () => {
        // console.log('Cancel confirmed')
        terminateConcurrentWebWorker()
        terminateConcurrentUpload()

        globalBlobToUploadQueue = []
        setUploadIntegrityCheckCounter(0)
        let nextState = uploaderStore.isUpdateExistingData
                            ? transition({ type: VALID_FSM_EVENT_TYPE.CANCEL_UPDATE })
                            : transition({ type: VALID_FSM_EVENT_TYPE.CANCEL_UPLOAD })
        if(heartbeatWebWorker) {
            heartbeatWebWorker.postMessage({ msg: 'terminate' })
            console.log(`Heartbeat webworker terminated`)
            setHeartbeatWebWorker(undefined)
        }
        setIsSyncStatusDialogOpen(true) 
        handleUpdateUploadStatusToDB(nextState.status)
            .then(response => {
                updateUploadStatus(nextState.status)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setIsSyncStatusDialogOpen(false))
        setIsUploadCancelDialogOpen(false)
    }

    const handleUploadFolderInputOnChange = (event: BaseSyntheticEvent) => {
        // This is an exception because GENERATING_METADATA_N_CHECK_PROGRESS_FAILED is a downstream stage of UPLOAD_PAUSED
        // Resume from an UPLOAD_PAUSED don't need to select files again
        if(uploaderStore.status === VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED) {
            handleUploadFolderInputOnResume(event)
        } else {
            setFileList(event.target.files as FileList)
            setIsFileSelectionDialogOpen(true)
        }
    }

    // Skip file selection when resume an upload
    const handleUploadFolderInputOnResume = (event: BaseSyntheticEvent) => {
        // keep consistency with the return value of function makeFileSelection
        setFileList(Array.from(event.target.files as FileList))
        updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.FOLDER_SELECTED }).status)
    }
    
    // Steps: 
    //      file selection (original status is READY_FOR_UPLOAD)
    //          --> folder validation (status changed to VALIDATING_SOURCE)
    //              --> validation result present 
    //                  --> GPS check 
    //                      --> cross strips check 
    //                          --> start upload (status changed to UPLOADING)
    const makeFileSelection = (selectedFileList: Array<File>) => {
        // console.log('makeFileSelection: selectedFileList: ', typeof(selectedFileList), typeof(selectedFileList[0]), selectedFileList)
        setIsFileSelectionDialogOpen(false)
        setFileList(selectedFileList)
        updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.FOLDER_SELECTED }).status)
    }

/*
    Validate specific files before upload
        1. checkGPS
        2. checkCrossStrip
        3. checkFmsLogMismatchProject
        4. checkFmsLogMismatchTime
        5. checkLazHeader
        6. checkLazRgbn
        7. checkBlankFile
*/
    const checkGPS = (disableCompression: boolean=false) => {
        console.log("checkGPS: disableCompression: ", disableCompression)
        setIsGlobalDisableCompression(disableCompression)

        let gpsPartDict: {[key: string]: boolean} = {};
        let gpsStartDict: {[key: string]: boolean} = {};
        // let fileList = this.state.pendingFilesMetadata.fileList;
        fileList && Array.from(fileList).forEach(item => {
            if (RegExp(/^FL\d{6}_\d{3}\/FL\d{6}(_\d{2})*\.\d{3}$/, "g").test((item as any).webkitRelativePath)) {
                let part = (item as any).webkitRelativePath.split('/')[1].slice(0, -4)
                if (gpsPartDict[part] === undefined)
                    gpsPartDict[part] = true;
            }
            if (RegExp(/^FL\d{6}_\d{3}\/FL\d{6}(_\d{2})*\.000$/, "g").test((item as any).webkitRelativePath)) {
                let part = (item as any).webkitRelativePath.split('/')[1].slice(0, -4)
                if (gpsStartDict[part] === undefined)
                    gpsStartDict[part] = true;
            }
        });
        let missingGPSStartFiles = [];
        for (let [key, value] of Object.entries(gpsPartDict)) {
            if (!gpsStartDict[key]) {
                missingGPSStartFiles.push(key)
            }
        }

        if (missingGPSStartFiles.length > 0) {
            console.log('Check GPS files: Missing GPS files found')
            setMissingGPSStartFilenameList(missingGPSStartFiles)
            setIsGPSCheckDialogOpen(true)
        }
        else {
            // console.log('Check GPS files: PASSED')
            checkCrossStrip()
        }
    }

    const checkCrossStrip = () => {
        console.log(`Check cross strips...`)
        if(isMissingFlightplanCrossStrip) {
            setIsMissingFlightPlanCSDialogOpen(isMissingFlightplanCrossStrip)
        } else {
            // setIsFolderValidationDialogOpen(false)
            // updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.VALIDATION_SUCCEED }).status)
            checkFmsLogMismatchProject()
        }
    }

    const checkFmsLogMismatchProject = () => {
        console.log(`Check FMS log files for mismatch projects...`)
        let alertMsg = (folderValidationSummary as FileRuleSummary)['alertMessages'].filter(alert => alert.name === 'FMSLogMismatchProject')[0]
        if(alertMsg) {
            // console.log(alertMsg)
            setFmsLogFilenameListWithNoProjectId((alertMsg.message as FMSLogMismatchProjectAlertMessage).fmsLogFilenameListWithNoProjectId || [])
            setFmsLogFilenameListWithUnexpectedProjectId((alertMsg.message as FMSLogMismatchProjectAlertMessage).fmsLogFilenameListWithUnexpectedProjectId || [])
            setMissingProjectIdList((alertMsg.message as FMSLogMismatchProjectAlertMessage).missingProjectIdList || [])
            setIsFmsLogMismatchProjectAlertDialogOpen(true)
        } else {
            checkFmsLogMismatchTime()
        }
    }

    const checkFmsLogMismatchTime = () => {
        console.log(`Check FMS log files for mismatch time overlap...`)
        let alertMsg = (folderValidationSummary as FileRuleSummary)['alertMessages'].filter(alert => alert.name === 'FMSLogMismatchTime')[0]
        if(alertMsg) {
            // console.log(alertMsg)
            setFmsLogFilenameList((alertMsg.message as FMSLogMismatchTimeAlertMessage).fmsLogFilenameList)
            setIsFmsLogMismatchTimeAlertDialogOpen(true)
        } else {
            checkLazHeader()
        }
    }

    const checkLazHeader = () => {
        console.log(`Check terrain mapper laz file headers...`)
        let alertMsg = (folderValidationSummary as FileRuleSummary)['alertMessages'].filter(alert => alert.name === 'lazHeader')[0]
        if (alertMsg)  {
            setLazFilenameListWithInvalidHeader(alertMsg.message.invalidLazFilenameList)
            setIsLazHeaderAlertDialogOpen(true)
        } else {
            checkLazRgbn()
        }
    }

    const checkLazRgbn = () => {
        console.log(`Check terrain mapper laz file NIR info...`)
        let alertMsg = (folderValidationSummary as FileRuleSummary)['alertMessages'].filter(alert => alert.name === 'lazRGBN')[0]
        if (alertMsg)  {
            setLazFilenamelistWithoutRGBN(alertMsg.message.filenameListWithoutRGBN)
            setIsLazRgbnAlertDialogOpen(true)
        } else {
            checkBlankFile()
        }
    }

    const checkBlankFile = () => {
        console.log(`Check blank files...`)
        let alertMsg = (folderValidationSummary as FileRuleSummary)['alertMessages'].filter(alert => alert.name === 'blankFiles')[0]
        if(alertMsg) {
            setBlankFileList(alertMsg.message)
            setIsBlankFileAlertDialogOpen(true)
        } else {
            setIsFolderValidationDialogOpen(false)
            updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.VALIDATION_SUCCEED }).status)
        }
    }

    const removeRootFolderPath = (fullPath: string): string => {
        return fullPath.split('/').slice(1).join('/')
    }
    
    const filterInvalidFile = (fileRuleSummary: FileRuleSummary) => {
        if(fileRuleSummary.invalidFiles.length) {
            const filteredFileList = (fileList as Array<File>).filter(file => {
                let invalidFileFullPath = removeRootFolderPath((file as any).webkitRelativePath)
                return fileRuleSummary.invalidFiles.indexOf(invalidFileFullPath) < 0
            })
            setFileList(filteredFileList)
        }
    }

    const folderValidation =  async () => {
        let projectIdList: Array<string> = []
        if(uploaderStore.flight && (uploaderStore.flight as unknown as FetchFlightByFlightIdResponse).Sensors) {
            let sensorList = (uploaderStore.flight as unknown as FetchFlightByFlightIdResponse).Sensors
            sensorList.forEach((sensor: any) => {
                let projectId = sensor['ProjectID'] || undefined
                if(projectIdList.indexOf(projectId) === -1) {
                    projectIdList.push(projectId)
                }
            })
        }
        // let matchedSensorNumber = (uploaderStore.uploadMeta as UploadMeta).sensorName.match(/\d{2,3}$/)
        // if(matchedSensorNumber) {
        //     var sensorNumber = ('000' + matchedSensorNumber[0]).slice(-3)
        // } else {
        //     var sensorNumber = '000'
        // }
        let folderValidationHelperReturn = await FolderValidationHelper({
            sensorEquipment: uploaderStore.sensorEquipmentList as FetchSensorEquipmentListResponse,
            sensorName: (uploaderStore.uploadMeta as UploadMeta).sensorName,
            fileList: fileList as Array<File>,
            flightId: (uploaderStore.uploadMeta as UploadMeta).flightId,
            // sensorNumber,
            projectIdList,
            isPreexistingUpload: false,//only individual files are evaluated if this is true, not the whole.
            uploadTypes: [(uploaderStore.uploadMeta as UploadMeta).uploadType] ,
            maxChunkSizeMB: 200,//400MB leaves a buffer so as to not touch the edges of teh chrome 500 limit & to keep lambda decompression memory lower. A sweet spot.
            isSimultaneousImagery: true,
            processingTemplate: (uploaderStore.uploadMeta as UploadMeta).processingTemplate,
            departureTimezone: (uploaderStore.flight as FetchFlightByFlightIdResponse).DepartureTimezone,
            wheelsUp: (uploaderStore.flight as FetchFlightByFlightIdResponse).WheelsUp,
            wheelsDown: (uploaderStore.flight as FetchFlightByFlightIdResponse).WheelsDown
        });
        const { fileRuleSummary, isMissingFlightplanCS: isMissingFlightplanCrossStrip } = folderValidationHelperReturn
        // console.log('fileRuleSummary: ', fileRuleSummary)

        if ((uploaderStore.uploadMeta as UploadMeta).uploadType === 'TerrainMapperGPS') {
            // hide all invalid files
            let fileRuleSummaryWithEmptyInvalidFiles: FileRuleSummary = {
                ...fileRuleSummary,
                invalidFiles: []
            }
            setFolderValidationSummary(fileRuleSummaryWithEmptyInvalidFiles)
        } else {
            setFolderValidationSummary(fileRuleSummary)
        }
        // setIsMissingFlightPlanCSDialogOpen(isMissingFlightplanCS)
        setIsMissingFlightplanCrossStrip(isMissingFlightplanCrossStrip)
        setIsFolderValidationDialogOpen(true)
        // filter out invalid files from fileList
        filterInvalidFile(fileRuleSummary)

        return folderValidationHelperReturn
    }

    const generateMetadataNUploadTask = () => {
        const metadataGenerationHelper = new MetadataGenerationHelper(
                                                (fileList as Array<File>), 
                                                ([(uploaderStore.uploadMeta as UploadMeta).uploadType]),
                                                (uploaderStore.uploadMeta as UploadMeta).flightId,
                                                uploaderStore.isUpdateExistingData
                                            )
        const generateMetadataResult = metadataGenerationHelper.generateMetadata()
        addUploadMetadataList(generateMetadataResult.fileMetadataList)
        // console.log('generateMetadataResult: ', generateMetadataResult)
        const generateUploadTaskResult = metadataGenerationHelper.generateUploadTask()
        addUploadTaskList(generateUploadTaskResult.uploadTaskList)
        // console.log('generateUploadTaskResult: ', generateUploadTaskResult)

        let totalFileSize = generateMetadataResult.totalFileSize
        let totalFileCount = generateUploadTaskResult.uploadTaskList.length
        updateGlobalUploadProgress({ totalFileSize, totalFileCount })
    }

    const handleOnSkipToFileSelection = (event: BaseSyntheticEvent) => {
        setIsUpdateExistingDataDialogOpen(false)
        handleUploadFolderInputOnChange(event)
    }

    const launchNewWebWorker = (): number => {
        if(Object.keys(concurrentWebWorkerLookup).length < MAX_CONCURRENT_WEB_WORKER) {
            let worker = new WebWorker(ZstdCompressionWorker) as Worker
            let workerId = Object.keys(concurrentWebWorkerLookup).length
            concurrentWebWorkerLookup[workerId] = worker
            worker.onerror = function (event) {
                console.log("Compression web worker error ", event.message, event.filename, event.lineno, event);
            }
            const handleMessageEvent = (event: any ) => {
                if (event.data.message === 'progress' || event.data.message === 'completed') {
                    let percentCompleted = event.data.metadata ? Math.floor(event.data.metadata.percent) : 0
                    console.log(`WebWorker compression progress: fileName: ${event.data.metadata.fullPath}, percent: ${percentCompleted}%`)
                    updateUploadOngoingTaskProgress(event.data.metadata.fullPath, percentCompleted)
                }
                if (event.data.message === 'completed') {
                    worker.removeEventListener('message', handleMessageEvent)
                    let newBlob = new Blob([event.data.arrayBuffer], { type: 'application/octet-stream' });//{ type: 'application/zip' });

                    let fullPath = event.data.metadata.fullPath
                    console.log('fullPath: ', fullPath, 'Blob length after compression: ', event.data.arrayBuffer.byteLength)
                    // printLog(`Compression Completed: ${uploadTask.fullPath}`)
                    let blobToUpload: BlobToUpload = {
                        blob: newBlob,
                        fullPath: fullPath,
                        originalChunkSize: event.data.uncompressedChunkSize
                    };
                    enqueueBlobToUploadQueue(blobToUpload)
                    updateUploadOngoingTaskStatus(fullPath, VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD)
                    // removeWebWorkerFromLookup(fullPath)
                    idleWebWorkerLookup[workerId] = worker
                }
            }
            worker.addEventListener('message', event => handleMessageEvent(event))
            return workerId
        } else {
            return -1
        }
    }

    const hasIdleWebWorker = () => !!(Object.keys(concurrentWebWorkerLookup).length && Object.keys(idleWebWorkerLookup).length)
    const isReachMaxWebWorker = () => !!(Object.keys(concurrentWebWorkerLookup).length >= MAX_CONCURRENT_WEB_WORKER)

    // VALID_STATUS.UPLOADING
    useEffect(() => {
        // READING + COMPRESSING <= MAX_CONCURRENT_WEB_WORKER
        // WAITING_FOR_UPLOAD <= MAX_UPLOAD_WAITING_QUEUE_DEPTH or fileToUploadQueue.length <= MAX_UPLOAD_WAITING_QUEUE_DEPTH
        // UPLOADING <= MAX_CONCURRENT_UPLOAD
        if(uploaderStore.status == VALID_STATUS.UPLOADING) {
            // A list of tasks is waiting for you
            // let preprocessingOngoingTaskKeyList: Array<string> = Object.keys(uploaderStore.ongoingTaskLookup).filter(key => 
            //     uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.READING
            //         || uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.COMPRESSING
            // )
            let ReadingTaskKeyList: Array<string> = Object.keys(uploaderStore.ongoingTaskLookup).filter(key =>
                uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.READING
            )
            const isAllowedToReadMore = hasIdleWebWorker() 
                                        // ? (ReadingTaskKeyList.length < MAX_CONCURRENT_WEB_WORKER)
                                        ? (ReadingTaskKeyList.length < Object.keys(idleWebWorkerLookup).length)
                                            && (ReadingTaskKeyList.length < MAX_CONCURRENT_READING_CHUNK)
                                            // && (ReadingTaskKeyList.length < 1)
                                            && ((ReadingTaskKeyList.length + globalBlobToUploadQueue.length) < (MAX_UPLOAD_WAITING_QUEUE_DEPTH + 1))
                                        : isReachMaxWebWorker()
                                            ? false
                                            // : (ReadingTaskKeyList.length < MAX_CONCURRENT_WEB_WORKER)
                                            : (ReadingTaskKeyList.length < MAX_CONCURRENT_READING_CHUNK)
                                            // : (ReadingTaskKeyList.length < 1)

            if(uploaderStore.uploadTaskList.length > 0 
                // && preprocessingOngoingTaskKeyList.length < MAX_CONCURRENT_WEB_WORKER
                && isAllowedToReadMore
                && globalBlobToUploadQueue.length < MAX_UPLOAD_WAITING_QUEUE_DEPTH) {
                // console.log(`preprocessingOngoingTaskKeyList: ${preprocessingOngoingTaskKeyList.length}, globalBlobToUploadQueue: ${globalBlobToUploadQueue.length}, uploadTaskList: ${uploaderStore.uploadTaskList.length}`)

                // Provide flexibility. It is 0 by default. Could be modified if you know what you are doing.
                let currentUploadTaskIndex = 0          
                let currentUploadTask = uploaderStore.uploadTaskList[currentUploadTaskIndex]
                let matchedFile: File = (fileList as FileList)[currentUploadTask.fileMetadataIndex]
                // console.log(`When read blob: ${currentUploadTask.fullPath}, ${Object.keys(concurrentWebWorkerLookup).length}, ${Object.keys(idleWebWorkerLookup).length}, ${hasIdleWebWorker()}, ${isReachMaxWebWorker()}, ${ReadingTaskKeyList.length}, ${JSON.stringify(ReadingTaskKeyList)}`)
                readBlobZip(currentUploadTask, matchedFile)
                addUploadOngoingTask(currentUploadTaskIndex)
            }
            // All tasks are done
            if(uploaderStore.uploadTaskList.length === 0
                    && Object.keys(uploaderStore.ongoingTaskLookup).length === 0) {
                if(fileList) {
                    Object.keys(concurrentWebWorkerLookup).forEach(workerId => {
                        concurrentWebWorkerLookup[workerId].postMessage(['terminate'])
                        delete idleWebWorkerLookup[workerId]
                        delete concurrentWebWorkerLookup[workerId]
                    })
                    let nextState = transition({ type: VALID_FSM_EVENT_TYPE.UPLOAD_ALL_TASKS_SUCCEED })
                    console.log(`Upload tasks done.`)
                    updateUploadStatus(nextState.status)
                } else {
                    console.log('FileList missed. Errors may occur while uploading...')
                }
            }
        }
    }, [uploaderStore.status, uploaderStore.uploadTaskList, uploaderStore.completedTaskList])

    useEffect(() =>{
        if(uploaderStore.status === VALID_STATUS.UPLOADING) {
            // console.log(`blobToUploadQueue: ${blobToUploadQueue.length}, ongoingTaskLookup: ${Object.keys(uploaderStore.ongoingTaskLookup).length}`)
            let concurrentUploadingOngoingTaskKeyList: Array<string> = Object.keys(uploaderStore.ongoingTaskLookup).filter(key => 
                uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.UPLOADING
            )
            if(globalBlobToUploadQueue.length > (MAX_UPLOAD_WAITING_QUEUE_DEPTH + MAX_CONCURRENT_WEB_WORKER)) {
                throw Error('BlobToUpload queue is overflowed')
            } else if(globalBlobToUploadQueue.length !== 0 
                    && concurrentUploadingOngoingTaskKeyList.length < MAX_CONCURRENT_UPLOAD) {
                // // Force to update localStorage heartbeat 
                // let storedHeartbeatTimestamp = Number(window.localStorage.getItem(LOCAL_STORAGE_HEARTBEAT_TIMESTAMP))
                // let currentTimestamp = Number(Date.now())
                // if((currentTimestamp - storedHeartbeatTimestamp) > MAX_HEARTBEAT_FORCE_UPDATE_INTERVAL) {
                //     // handleUpdateUploadHeartbeat(processingId)        // Do it on heartbeat web worker
                //     updateLocalStorageHeartbeatTimestamp()
                //     console.log(`Force to update localStorage heartbeat at ${currentTimestamp}, Interval: ${(currentTimestamp - storedHeartbeatTimestamp) / 1000}s `)
                // }

                let currentBlobToUpload = dequeueBlobToUploadQueue()
                if(currentBlobToUpload) {
                    updateUploadOngoingTaskStatus(currentBlobToUpload.fullPath, VALID_ONGOING_TASK_STATUS.UPLOADING)
                    updateUploadOngoingTaskProgress(currentBlobToUpload.fullPath, 0)        // reset the progress
                    let cancelTokenSource = axios.CancelToken.source()
                    globalCancelTokenLookup[currentBlobToUpload.fullPath] = cancelTokenSource
                    handleUploadBlob(currentBlobToUpload.fullPath, 
                                     currentBlobToUpload.blob,
                                     cancelTokenSource.token)
                        .then((response: { [key: string]: any}) => {
                            delete globalCancelTokenLookup[(currentBlobToUpload as BlobToUpload).fullPath]
                            if(response && response.hasOwnProperty('isCancelled') && response['isCancelled']) {
                                // console.log(`Response: ${JSON.stringify(response, null, 4)}`)
                                // return null
                            } else {
                                // printLog(`Upload Completed: ${(currentBlobToUpload as BlobToUpload).fullPath}`)
                                updateUploadOngoingTaskStatus((currentBlobToUpload as BlobToUpload).fullPath, VALID_ONGOING_TASK_STATUS.COMPLETE)
                                console.log(`updateGlobalUploadProgress: ${(currentBlobToUpload as BlobToUpload).fullPath}, ${(currentBlobToUpload as BlobToUpload).originalChunkSize}`)
                                updateGlobalUploadProgress({
                                    currentCompletedFileSize: (currentBlobToUpload as BlobToUpload).originalChunkSize,
                                    globalSpeed: 0
                                })
                            }
                        })
                }
            }
            let completedOngoingTaskKeyList: Array<string> = Object.keys(uploaderStore.ongoingTaskLookup).filter(key => 
                uploaderStore.ongoingTaskLookup[key].status === VALID_ONGOING_TASK_STATUS.COMPLETE
            )
            // console.log(`completedOngoingTaskKeyList: ${completedOngoingTaskKeyList}`)
            let hasTaskComplete: Boolean = completedOngoingTaskKeyList.length > 0
            if(hasTaskComplete) {
                completedOngoingTaskKeyList.forEach(key => {
                    completeUploadOngoingTask(key)
                })
            }
        }
    }, [uploaderStore.ongoingTaskLookup])

    const readBlobZip = (uploadTask: UploadTask, file: File, retries: number=0) => {
        if (retries === 3)
            return
        const { chunkSize } = uploadTask
        let offset = uploadTask.chunkIndex * 200 * 1024 * 1024      // 200MB
        const reader = new FileReader();

        reader.onerror = function (evt) {
            console.error("Error in readBlobZip" + (evt.target as FileReader).error)
            readBlobZip(uploadTask, file, retries + 1);
        }

        let blob = file.slice(offset, offset + chunkSize);
        reader.onload = function (evt) {
            console.log('Read Blob Done...')
            let fileProperties = {
                name: uploadTask.targetFullPath,
                webkitRelativePath: uploadTask.fullPath,
                lastModifiedDate: (file as any).lastModifiedDate
            }
            if(uploadTask.isZipped) {
                let workerId = null
                if(!isReachMaxWebWorker()) {
                    workerId = launchNewWebWorker()
                } else {
                    workerId = Object.keys(idleWebWorkerLookup)[0]
                    delete idleWebWorkerLookup[workerId]
                }
                updateUploadOngoingTaskStatus(uploadTask.fullPath, VALID_ONGOING_TASK_STATUS.COMPRESSING)
                
                console.log(`Worker ${workerId} is assigned a job: ${uploadTask.fullPath}`)
                let worker = concurrentWebWorkerLookup[workerId]
                worker.postMessage([ 'start', (evt.target as FileReader).result, fileProperties, uploadTask.chunkSize, isGlobalDisableCompression], 
                                        [((evt.target as FileReader).result as ArrayBuffer)]
                                    );
            } else {
                console.log(`File is not zipped: ${uploadTask.fullPath}, Blob length: ${uploadTask.totalOriginalSize}`)
                let newBlob = new Blob([((evt.target as FileReader).result as ArrayBuffer)], { type: 'application/octet-stream' })
                let blobToUpload: BlobToUpload = {
                    blob: newBlob,
                    fullPath: uploadTask.fullPath,
                    originalChunkSize: uploadTask.chunkSize
                };
                enqueueBlobToUploadQueue(blobToUpload)
                updateUploadOngoingTaskStatus(uploadTask.fullPath, VALID_ONGOING_TASK_STATUS.WAITING_FOR_UPLOAD)
            }
            // setChunkReadCounter(prevState => (prevState + 1))
        };
        reader.readAsArrayBuffer(blob)
    }

    const enableResumeDialog = async() => {
        const handleFetchUploadProgressResponse = await handleFetchUploadProgress()
        // console.log(`handleFetchUploadProgress: ${JSON.stringify(handleFetchUploadProgressResponse, null, 4)}`)
        if(Object.keys(handleFetchUploadProgressResponse).length !== 0) {
            const { 
                // remaining_task_key_list: remainingTaskKeyList,
                completed_file_size: completedFileSize, 
                total_file_size: totalFileSize,
                // total_file_count: totalFileCount,
            } = handleFetchUploadProgressResponse as FetchUploadProgressResponse
            // const completedFileCount = totalFileCount - remainingTaskKeyList.length
            updateGlobalUploadProgress({ completedFileSize, totalFileSize })
            // setIsUploadResumeBtnEnabled((uploaderStore.uploadMeta as UploadMeta).user
            //                                 ? (uploaderStore.uploadMeta as UploadMeta).user === user
            //                                 : true
            //                             )
            setIsUploadResumeBtnEnabled(true)
        }
    }

    // VALID_STATUS.UPLOAD_INTEGRITY_CHECK
    useEffect(() => {
        if(uploaderStore.status === VALID_STATUS.UPLOAD_INTEGRITY_CHECK) {
            generateMetadataNUploadTask()
            handleCheckUploadProgress()
                .then(response => {
                    if((response as CheckUploadProgressErrorResponse).reason) {             // Failed to pass file consistency check
                        throw Error((response as CheckUploadProgressErrorResponse).reason)
                    } else {
                        console.log('Updating upload task list:')
                        console.log(`    Remaining Upload Tasks Count: ${(response as CheckUploadProgressResponse).remaining_task_key_list.length}`)
                        filterRemainingUploadTask((response as CheckUploadProgressResponse).remaining_task_key_list)
                        return (response as CheckUploadProgressResponse)
                    }
                })
                .then(response => {
                    if(response.remaining_task_key_list.length) {
                        console.log(`Upload integrity check completed with missing files detected`)
                        if(uploadIntegrityCheckCounter <= MAX_UPLOAD_INTEGRITY_CHECK_RETRY) {
                            const { 
                                remaining_task_key_list: remainingTaskKeyList,
                                completed_file_size: completedFileSize, 
                                total_file_size: totalFileSize,
                                total_file_count: totalFileCount,
                            } = response as CheckUploadProgressResponse
                            const completedFileCount = totalFileCount - remainingTaskKeyList.length
                            updateGlobalUploadProgress({ completedFileSize, totalFileSize, completedFileCount, totalFileCount })
                            let nextState = transition({ type: VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_MISSING_FILE_DETECTED })
                            updateUploadStatus(nextState.status)
                        } else {
                            console.log('Reached maximum upload integrity check retry. Skip next integrity check.')
                            // TODO: Add an proper alert or a reminder to hint to user
                            let nextState = transition({ type: VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_REACH_MAX_RETRY })
                            setIsSyncStatusDialogOpen(true)
                            handleUpdateUploadStatusToDB(nextState.status)
                                .then(response => {
                                    updateUploadStatus(nextState.status)
                                    setUploadIntegrityCheckCounter(0)
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                                .finally(() => setIsSyncStatusDialogOpen(false))
                        }
                    } else {
                        console.log(`Upload integrity check SUCCEED`)
                        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_SUCCEED })
                        setIsSyncStatusDialogOpen(true)
                        handleUpdateUploadStatusToDB(nextState.status)
                        .then(response => {
                            updateUploadStatus(nextState.status)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                        .finally(() => setIsSyncStatusDialogOpen(false))
                    }
                })
                .catch(error => {
                    console.log(error)
                    setFileList(null)
                    cancelUploadTask()
                    let nextState = transition({ type: VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_FAILED })
                    updateUploadStatus(nextState.status)
                })
        }
    }, [uploadIntegrityCheckCounter])

    // flightId + sensor serial number
    const generateFolderName = (flightId: string, sensorName: string): string => {
        const sensorSerialPattern = /^([a-zA-z0-9\s]+)\s-\s(\d{2,3})$/
        const matchResult = sensorName.match(sensorSerialPattern)
        if(matchResult) {
            return flightId + '_' + matchResult[2]
        } else {
            return flightId
        }
    }

    // Simulate a queue
    const enqueueBlobToUploadQueue = (newBlobToUpload: BlobToUpload) => {
        globalBlobToUploadQueue.push(newBlobToUpload)
    }

    const dequeueBlobToUploadQueue = (): BlobToUpload | null => {
        if(globalBlobToUploadQueue.length === 0) {
            return null
        } else {
            let dequeuedFile: BlobToUpload = globalBlobToUploadQueue[0]
            let newBlobToUploadQueue = globalBlobToUploadQueue.slice(1)   // from index 1 to the last one
            globalBlobToUploadQueue = newBlobToUploadQueue
            return dequeuedFile
        }
    }

    const removeWebWorkerFromLookup = (workerId: string) => {
        // setConcurrentWebWorkerLookup(prevState => {
        //     let newState = Object.assign({}, prevState)
        //     delete newState[workerId]
        //     duplicatedConcurrentWebWorkerLookup = { ...newState }
        //     return {
        //         ...newState,
        //     }
        // })
        delete concurrentWebWorkerLookup[workerId]
    }

    const setFetchUploadValidationResultTimer = (interval: number) => {
        let intervalId = window.setInterval(async () => {
            console.log(`Upload validation is processing...`)
            try {
                const currentUploadRecord = await handleFetchUploadRecord()
                if(Object.keys(currentUploadRecord).length) {
                    let currentUploadStatus = (currentUploadRecord as FetchUploadRecordResponse).Status
                    if([VALID_STATUS.VALIDATING_UPLOAD_FAILED, VALID_STATUS.DATA_READY].indexOf(currentUploadStatus) >= 0) {
                        updateUploadStatus(currentUploadStatus)
                    }
                } else {
                    console.log(`Failed to get upload validation result. Please refresh the page.`)
                }
            } catch(error) {
                console.log(error)
            }
        }, interval)
        return intervalId 
    }

    // This is primarily for a rarely occurring issue, which is that the rebuild process retries after the validation fails 
    const setUploadValidationResultPolling = (interval: number) => {
        let intervalId = window.setInterval(async () => {
            console.log(`Upload status is syncing...`)
            try {
                const currentUploadRecord = await handleFetchUploadRecord()
                if(Object.keys(currentUploadRecord).length) {
                    let currentUploadStatus = (currentUploadRecord as FetchUploadRecordResponse).Status
                    if([VALID_STATUS.VALIDATING_UPLOAD, VALID_STATUS.DATA_READY].indexOf(currentUploadStatus) >= 0) {
                        updateUploadStatus(currentUploadStatus)
                    }
                } else {
                    console.log(`Failed to get upload validation result. Please refresh the page.`)
                }
            } catch(error) {
                console.log(error)
            }
        }, interval)
        return intervalId 
    }

    const clearFetchUploadValidationResultTimer = (intervalId: number) => {
        window.clearTimeout(intervalId)
    }

    const isNeedRenewToken = (jwtToken: string, minRemainingTime: number): boolean => {
        let jwtInfo = JSON.parse(atob(jwtToken.split(".")[1]))
        // console.log(`Remaining time: ${jwtInfo.exp - (Date.now() / 1000)}`)
        if(jwtInfo.exp && (jwtInfo.exp - (Date.now() / 1000) < minRemainingTime)) {
            console.log(`Less than ${minRemainingTime} secs left on token, attempting to renew...`)
            return true
        } else {
            return false
        }
    }

    // Token renew logic is replace by Component PrivateRoute
    // const setHeartbeatTimer = (pollingInterval: number, worker: Worker | null): number => {
    //     let intervalId = window.setInterval(() => {
    //         // updateLocalStorageHeartbeatTimestamp()
    //         // Renew token if needed
    //         let currentToken = handleAccessCurrentReduxAuthState()
    //         if(isNeedRenewToken((currentToken as string), TOKEN_MIN_REMAINING_TIME)) {
    //             renewToken(currentToken)            // the legacy renewToken stores the new token to localStorage
    //             .then((response: string | null) => {
    //                 // response && localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response)
    //                 if(response && worker) {
    //                     worker.postMessage({ 
    //                         msg: 'tokenRenew',
    //                         url: BASE_URI + `flights/${(uploaderStore.uploadMeta as UploadMeta).flightId}/upload/${(uploaderStore.uploadMeta as UploadMeta).uploadId}/heartbeat`,
    //                         token: response,
    //                         timeInterval: HEARTBEAT_INTERVAL,
    //                     })
    //                 }
    //             })
    //         }
    //     }, pollingInterval)
    //     return intervalId 
    // }

    // const clearHeartbeatTimer = (intervalId: number) => {
    //     window.clearTimeout(intervalId)
    // }

    const setCheckUploadQueueTimer = (pollingInterval: number): number => {
        let nextState: { status: string } | undefined
        let intervalId = window.setInterval(() => {
            handleCheckConcurrentUpload(browserFingerprint)
            .then(response => {
                if(response) {
                    let queueIndex = (response as CheckConcurrentUploadResponse).queueIndex
                    if(queueIndex >= 1) {
                        setUploadQueueIndex(queueIndex)
                    } else if(queueIndex == 0) {
                        setUploadQueueIndex(-1)
                        nextState = transition({ type: VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_NOT_DETECTED })
                    } else {
                        setUploadQueueIndex(-1)
                        nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED })
                    }
                    // console.log(`setCheckUploadQueueTimer: ${JSON.stringify(response, null, 4)}`)
                } else {
                    setUploadQueueIndex(-1)
                    nextState = transition({ type: VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED })
                }
                if(nextState) {
                    setIsSyncStatusDialogOpen(true)
                    handleUpdateUploadStatusToDB(nextState.status)
                    .then(response => {
                        updateUploadStatus((nextState as { status: string }).status)
                    })
                    .finally(() => setIsSyncStatusDialogOpen(false))

                }
            })
            .catch(error => {
                console.log(error)
            })
        }, pollingInterval)
        return intervalId 
    }

    const clearCheckUploadQueueTimer = (intervalId: number) => {
        window.clearTimeout(intervalId)
    }

    const handleQuitUploadQueue = () => {
        let nextState = transition({ type: VALID_FSM_EVENT_TYPE.QUIT_UPLOAD_QUEUE })
        setUploadQueueIndex(-1)
        setIsUploadQueuingDialogOpen(false)
        setIsSyncStatusDialogOpen(true)
        // Have to update local status first. Otherwise it may run into 
        updateUploadStatus((nextState as { status: string }).status)
        handleUpdateUploadStatusToDB(nextState.status)
        // .then(response => {
        //     updateUploadStatus((nextState as { status: string }).status)
        // })
        .catch(error => {
            console.log(error)
        })
        .finally(() => setIsSyncStatusDialogOpen(false))
    }

    const resetAllNonBinary = () => {
        setFileList(null)
        setFolderValidationSummary(undefined)
        setMissingGPSStartFilenameList(undefined)
    }

    // Finite State Machine
    // A simplified state machine to tell what the next state will be....
    const machine: any = {
        initial: VALID_STATUS.UPLOAD_INIT,
        states: {
            // state status
            // [VALID_STATUS.UPLOAD_INIT]: {
            //     on: {
            //         [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
            //             target: VALID_STATUS.CREATING_UPLOAD_ENTRY,
            //         }
            //     }
            // },
            [VALID_STATUS.READY_FOR_UPLOAD]: {
                on: {
                // event types
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.VALIDATING_SOURCE,
                    }
                }
            },
            [VALID_STATUS.VALIDATING_SOURCE]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.VALIDATION_SUCCEED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK,
                    },
                    [VALID_FSM_EVENT_TYPE.VALIDATION_FAILED]: {
                        target: VALID_STATUS.VALIDATING_SOURCE_FAILED,
                    }
                }
            },
            [VALID_STATUS.VALIDATING_SOURCE_FAILED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.VALIDATING_SOURCE,
                    }
                }
            },
            [VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_SUCCEED]: {
                        target: VALID_STATUS.CHECKING_CONCURRENT_UPLOAD
                    },
                    [VALID_FSM_EVENT_TYPE.GENERATE_METADATA_N_UPLOAD_TASK_FAILED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK_FAILED
                    }
                }
            },
            [VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK_FAILED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.VALIDATING_SOURCE,
                    }
                }
            },
            [VALID_STATUS.CHECKING_CONCURRENT_UPLOAD]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_DETECTED]: {
                        target: VALID_STATUS.UPLOAD_QUEUED
                    },
                    [VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_NOT_DETECTED]: {
                        target: VALID_STATUS.UPLOADING
                    },
                    [VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED]: {
                        target: VALID_STATUS.UPLOAD_PAUSED
                    }
                }
            },
            [VALID_STATUS.UPLOAD_QUEUED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_DETECTED]: {
                        target: VALID_STATUS.UPLOAD_QUEUED
                    },
                    [VALID_FSM_EVENT_TYPE.CONCURRENT_UPLOAD_NOT_DETECTED]: {
                        target: VALID_STATUS.UPLOADING
                    },
                    [VALID_FSM_EVENT_TYPE.CHECK_CONCURRENT_UPLOAD_FAILED]: {
                        target: VALID_STATUS.UPLOAD_PAUSED
                    },
                    [VALID_FSM_EVENT_TYPE.QUIT_UPLOAD_QUEUE]: {
                        target: VALID_STATUS.UPLOAD_PAUSED
                    }
                }
            },
            [VALID_STATUS.UPLOADING]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.PAUSE_UPLOAD]: {
                        target: VALID_STATUS.UPLOAD_PAUSED,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPLOAD]: {
                        target: VALID_STATUS.UPLOAD_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPDATE]: {
                        target: VALID_STATUS.UPDATE_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.UPLOAD_ALL_TASKS_SUCCEED]: {
                        target: VALID_STATUS.UPLOAD_INTEGRITY_CHECK,
                    }
                }
            },
            [VALID_STATUS.UPLOAD_INTEGRITY_CHECK]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_SUCCEED]: {
                        target: VALID_STATUS.UPLOAD_COMPLETE,
                    },
                    [VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_MISSING_FILE_DETECTED]: {
                        target: VALID_STATUS.UPLOADING
                    },
                    [VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_FAILED]: {
                        target: VALID_STATUS.UPLOAD_STALLED
                    },
                    [VALID_FSM_EVENT_TYPE.UPLOAD_INTEGRITY_CHECK_REACH_MAX_RETRY]: {
                        target: VALID_STATUS.UPLOAD_STALLED
                    }
                }
            },
            [VALID_STATUS.UPLOAD_COMPLETE]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.WAIT_FOR_BACKEND_UPLOAD_VALIDATITON]: {
                        target: VALID_STATUS.VALIDATING_UPLOAD,
                    },
                }
            },
            [VALID_STATUS.VALIDATING_UPLOAD]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.BACKEND_REBUILD_SUCCEED]: {
                        target: VALID_STATUS.DATA_READY,
                    },
                    [VALID_FSM_EVENT_TYPE.BACKEND_REBUILD_FAILED]: {
                        target: VALID_STATUS.VALIDATING_UPLOAD_FAILED,
                    }
                }
            },
            [VALID_STATUS.UPLOAD_PAUSED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.RESUME_UPLOAD]: {
                        target: VALID_STATUS.CHECKING_CONCURRENT_UPLOAD,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPLOAD]: {
                        target: VALID_STATUS.UPLOAD_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPDATE]: {
                        target: VALID_STATUS.UPDATE_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS
                    }
                }
            },
            [VALID_STATUS.UPLOAD_STALLED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.RESUME_UPLOAD]: {
                        target: VALID_STATUS.CHECKING_CONCURRENT_UPLOAD,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPLOAD]: {
                        target: VALID_STATUS.UPLOAD_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.CANCEL_UPDATE]: {
                        target: VALID_STATUS.UPDATE_CANCELLED,
                    },
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS
                    }
                }
            },
            [VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.CHECK_PROGRESS_SUCCEED]: {
                        target: VALID_STATUS.CHECKING_CONCURRENT_UPLOAD
                    },
                    [VALID_FSM_EVENT_TYPE.CHECK_PROGRESS_FAILED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED
                    }
                }
            },
            [VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS
                    }
                }
            },
            [VALID_STATUS.UPLOAD_CANCELLED]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.VALIDATING_SOURCE,
                    },
                }
            },
            [VALID_STATUS.DATA_READY]: {
                on: {
                    [VALID_FSM_EVENT_TYPE.FOLDER_SELECTED]: {
                        target: VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK,
                    },
                }
            }
        }
    }

    const transition = (event: { type: string }) => {
        let currentState = uploaderStore.status
        try {
            let nextStateNode = machine.states[currentState].on[event.type]
            if(nextStateNode == undefined || nextStateNode == null) {
                nextStateNode = { 
                    target: currentState,
                }
            }
            return ({
                status: nextStateNode.target as string,
            })
        } catch(error) {
            console.log(`Invalid status transition: ${error}`)
            return { status: currentState }
        }
    }

    // Simple state machine pattern for button state management
    // Put all btn state management code here. The code is verbose but it makes logic clearer.
    const btnStateTransition = (currentStatus: string = uploaderStore.status) => {
        switch(currentStatus) {
            // case VALID_STATUS.INIT:
            //     setIsSelectFolderBtnEnabled(false)
            //     setIsPauseResumeBtnEnabled(false)
            //     setIsPauseBtnDisplay(true)
            //     setIsCancelBtnEnabled(false)
            //     break
            case VALID_STATUS.UPLOAD_INIT:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.READY_FOR_UPLOAD:
                setIsSelectFolderBtnEnabled(true)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.VALIDATING_SOURCE:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.VALIDATING_SOURCE_FAILED:
                setIsSelectFolderBtnEnabled(true)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK_FAILED:
                setIsSelectFolderBtnEnabled(true)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.CHECKING_CONCURRENT_UPLOAD:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.UPLOAD_QUEUED:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.UPLOADING:
                if(fileList) {
                    setIsSelectFolderBtnEnabled(false)
                    setIsPauseResumeBtnEnabled(true)
                    setIsCancelBtnEnabled(true)
                } else {
                    setIsSelectFolderBtnEnabled(false)
                    setIsPauseResumeBtnEnabled(false)
                    setIsCancelBtnEnabled(false)
                }
                setIsPauseBtnDisplay(true)
                break
            case VALID_STATUS.UPLOAD_PAUSED:
                // Trying to resume on another session
                if(fileList) {
                    setIsSelectFolderBtnEnabled(false)
                    setIsPauseResumeBtnEnabled(true)
                } else {
                    setIsSelectFolderBtnEnabled(true)
                    setIsPauseResumeBtnEnabled(false)
                }
                setIsPauseBtnDisplay(false)
                setIsCancelBtnEnabled(true)
                break
            case VALID_STATUS.UPLOAD_STALLED:
                // Trying to resume on another session
                if(fileList) {
                    setIsSelectFolderBtnEnabled(false)
                    setIsPauseResumeBtnEnabled(true)
                } else {
                    setIsSelectFolderBtnEnabled(true)
                    setIsPauseResumeBtnEnabled(false)
                }
                setIsPauseBtnDisplay(false)
                setIsCancelBtnEnabled(true)
                break
            case VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(false)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED:
                setIsSelectFolderBtnEnabled(true)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(false)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.UPLOAD_CANCELLED:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.UPDATE_CANCELLED:
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.UPLOAD_INTEGRITY_CHECK:              
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(true)
                break
            case VALID_STATUS.UPLOAD_COMPLETE:              
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.VALIDATING_UPLOAD:              
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.VALIDATING_UPLOAD_FAILED:              
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            case VALID_STATUS.DATA_READY:              
                setIsSelectFolderBtnEnabled(false)
                setIsPauseResumeBtnEnabled(false)
                setIsPauseBtnDisplay(true)
                setIsCancelBtnEnabled(false)
                break
            default:
                break
        }
    }

    return (
        <>
            {/* {this.props.flight && this.props.flight.Upload && */}
            <Grid container direction="row" alignItems="center" justify="center" style={{ paddingTop: 8, paddingBottom: 8, background: "#FFFFFF", marginBottom: 8 }}>
                <div style={{ fontSize: 28, fontWeight: "bolder", color: "#1a3a69", marginRight: 8 }}>
                    <CloudUpload style={{ marginBottom: -6, fontSize: 32, paddingRight: 5 }} />
                    Upload:
                </div>
                <div style={{ fontSize: 28, fontWeight: "bold", color: "#000000" }}>
                    {(uploaderStore.uploadMeta as UploadMeta).flightId} : {(uploaderStore.uploadMeta as UploadMeta).sensorName} : {(uploaderStore.uploadMeta as UploadMeta).uploadType}
                </div>
            </Grid>
            {/* } */}

            {
                ((uploaderStore.status === VALID_STATUS.UPLOAD_PAUSED
                    || uploaderStore.status === VALID_STATUS.UPLOAD_STALLED) && fileList === null)      // initial status is UPLOAD_PAUSED
                    && (!uploaderStore.isFetchingUploadProgress
                        ? isUploadResumeBtnEnabled
                            ? <>
                                <UploadResume
                                    uploadType={(uploaderStore.uploadMeta as UploadMeta).uploadType}
                                    completedFileSizePercent={
                                        (uploaderStore.globalUploadProgress.completedFileSize / uploaderStore.globalUploadProgress.totalFileSize * 100)}
                                    totalFileSize={formatBytes(uploaderStore.globalUploadProgress.totalFileSize, 2)}
                                    path={generateFolderName((uploaderStore.uploadMeta as UploadMeta).flightId || '', (uploaderStore.uploadMeta as UploadMeta).sensorName || '')}
                                    // onResume={handleUploadFolderInputOnChange}
                                    onResume={handleUploadFolderInputOnResume}
                                    // disabled={!isUploadResumeBtnEnabled}
                                />
                            </>
                            : <>
                                <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                                    <strong className='mb-3'>Failed to fetch metadata. This might be caused by intermitten network connection.</strong>
                                    <strong>Please contact LiDARnetics Administrator to reset the upload.</strong>
                                </Grid>
                            </>
                        : <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                            <CircularProgress />
                            <div className='pt-5' >Loading prior incompleted upload details...</div>
                        </Grid>)
            }
            {
                ([VALID_STATUS.UPLOADING, VALID_STATUS.VALIDATING_UPLOAD, VALID_STATUS.VALIDATING_UPLOAD_FAILED, VALID_STATUS.UPLOAD_QUEUED].indexOf(uploaderStore.status) > -1 
                    && fileList === null)      // initial status is UPLOADING
                    && ((uploaderStore.uploadMeta as UploadMeta).user === user
                        ? <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                            <strong className='mb-3'>Errors may occur while uploading.</strong>
                            <strong>Please wait 5 minutes and refresh the page...</strong>
                        </Grid>
                        : <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                            <div style={{ fontWeight: 'bold' }}>FLIGHT UPLOAD UNDERWAY</div>
                            <p style={{ paddingTop: 5 }}>
                                Data for this flight is currently being uploaded by <span style={{ fontWeight: 'bold' }}>{(uploaderStore.uploadMeta as UploadMeta).user}</span>, 
                                commenced at <span style={{ fontWeight: 'bold' }}>{((uploaderStore.uploadMeta as UploadMeta).updated as string).split("T")[1]}</span> on <span style={{ fontWeight: 'bold' }}>{((uploaderStore.uploadMeta as UploadMeta).updated as string).split("T")[0]} UTC</span>.
                            </p>
                            <p>Please contact <span style={{ fontWeight: 'bold' }}>{(uploaderStore.uploadMeta as UploadMeta).user}</span>, or the LiDARnetics Administrator to update, replace or cancel this upload.</p>
                        </Grid>
                    )
            }
            {
                (uploaderStore.status === VALID_STATUS.DATA_READY && fileList === null)      // initial status is UPLOADING
                    && uploaderStore.uploadMeta
                    && <UploadReplace
                        uploadUser={uploaderStore.uploadMeta.user}
                        uploadDate={uploaderStore.uploadMeta.updated}
                        uploadFolder={uploaderStore.uploadMeta.uploadId}
                        uploadTypes={[uploaderStore.uploadMeta.uploadType]}
                        s3LidarBucket={(uploaderStore.uploadMeta.uploadType=='LiDAR'
                                            ? Config.UPLOAD_LIDAR_BUCKET : Config.UPLOAD_TM_LIDAR_BUCKET) + "/"}
                        s3ImageryBucket={Config.UPLOAD_IMAGERY_BUCKET + "/"}
                        onUpdateDisplay={() => setIsUpdateExistingDataDialogOpen(true)}
                        // onReplaceDisplay={() => { this.setState({ replaceDialogOpen: true }) }}      // temporarily disabled
                    />
            }
            {   ([
                    VALID_STATUS.UPLOAD_PAUSED, VALID_STATUS.UPLOAD_STALLED, VALID_STATUS.UPLOADING, 
                    VALID_STATUS.VALIDATING_UPLOAD, VALID_STATUS.VALIDATING_UPLOAD_FAILED,
                    VALID_STATUS.DATA_READY, VALID_STATUS.UPLOAD_QUEUED
                ].indexOf(uploaderStore.status) === -1 || fileList !== null)
                    && <>
                            <Grid container direction="row" alignItems="center" justify="space-around"
                                // style={{minWidth: 860}}
                                className='frame pb-1'
                            >
                                <Grid direction="row" alignItems="center" justify="center">
                                    <label htmlFor="uploadButton">
                                        <Button
                                            disabled={!isSelectFolderBtnEnabled}
                                            variant="contained" component="span" color="primary"
                                            style={{ width: 240, height: 40 }}
                                            onClick={handleSelectFolderBtnOnClick}
                                        >
                                            Upload Flight Folder
                                        </Button>
                                    </label>
                                    <input
                                        id="uploadButton"
                                        disabled={!isSelectFolderBtnEnabled}
                                        style={{ display: 'none' }}
                                        onChange={(event) => handleUploadFolderInputOnChange(event)}
                                        ref={hiddenUploadFolderInput}
                                        multiple 
                                        type="file"
                                        accept="*"
                                    />
                                </Grid>
                                <Grid direction="row" alignItems="center" justify="center">
                                    {/* <UploadStages status={uploaderStore.status} /> */}
                                    <UploadStages 
                                        status={uploaderStore.status} 
                                        uploadIntegrityCheckCounter={uploadIntegrityCheckCounter}
                                    />
                                </Grid>
                            </Grid>

                        <Grid container direction="row" alignItems="center" justify="center" className='mb-1'
                        >   
                            <Grid container direction="row" alignItems="center" justify="center" md={1} className='p-0 m-0'>
                                {isPauseBtnDisplay
                                    ? <NicerTooltip placement="left" content={<span>Pause</span>}>
                                        <IconButton 
                                            aria-label="Pause" 
                                            disabled={!isPauseResumeBtnEnabled}
                                            className='iconbutton__button--medium'
                                        >
                                            <PauseCircleFilled
                                                style={{ fontSize: 35 }}
                                                onClick={handlePauseBtnOnClick}
                                                color={isPauseResumeBtnEnabled ? 'primary' : 'disabled'}
                                            />
                                        </IconButton>
                                    </NicerTooltip>
                                    : <NicerTooltip placement="left" content={<span>Resume</span>}>
                                        <IconButton 
                                            aria-label="Resume" 
                                            disabled={!isPauseResumeBtnEnabled}
                                            className='iconbutton__button--medium'
                                        >
                                            <PlayCircleFilled
                                                style={{ fontSize: 35 }}
                                                onClick={handleResumeBtnOnClick}
                                                color={isPauseResumeBtnEnabled ? 'primary' : 'disabled'}
                                            />
                                        </IconButton>
                                    </NicerTooltip>
                                }
                            </Grid>
                            <Grid container direction="row" alignItems="center" justify="center" md={1} className='p-0 m-0'>
                                <NicerTooltip placement="right" content={<span>Cancel</span>}>
                                    <IconButton aria-label="Cancel"
                                        disabled={!isCancelBtnEnabled}
                                        className='iconbutton__button--medium'
                                    >
                                        <Cancel
                                            style={{ fontSize: 35 }} 
                                            onClick={handleCancelBtnOnClick}
                                            color={isCancelBtnEnabled ? 'primary' : 'disabled'}
                                        />
                                    </IconButton>
                                </NicerTooltip>
                            </Grid>
                        </Grid>
                    </>
            }

            {
                uploaderStore.status === VALID_STATUS.GENERATING_METADATA_N_CHECK_PROGRESS_FAILED
                    && <>
                        <Grid container direction="column" alignItems="center" justify="center" className='mt-2'>
                            <p>File inconsistency detected.</p>
                            <p>Please select the original folder.</p>
                        </Grid>
                    </>
            }
            {
                uploaderStore.status === VALID_STATUS.GENERATING_METADATA_N_UPLOAD_TASK_FAILED
                    && <Grid container direction="column" alignItems="center" justify="center" className='p-5 mt-5'>
                        <strong className='mb-3'>Failed to upload metadata.</strong>
                        <strong>Please try again...</strong>
                    </Grid>
            }
            {isFileSelectionDialogOpen && 
                <FileSelectionDialog
                    title="Select new files to upload"
                    files={fileList}
                    isUpdate={uploaderStore.isUpdateExistingData}
                    onCancel={() => {
                        setIsFileSelectionDialogOpen(false)
                        setFileList(null)
                    }}
                    onConfirm={makeFileSelection}
                    open={isFileSelectionDialogOpen} 
                />
            }
            {isFolderValidationDialogOpen &&
                <FileValidationDialog
                    title="File Validation Results"
                    results={folderValidationSummary}
                    onCancel={() => {
                        setIsFolderValidationDialogOpen(false)
                        setIsGPSCheckDialogCancelled(true)
                        updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.VALIDATION_FAILED }).status)
                    }}
                    onUpload={(event: any) => {
                        setIsGPSCheckDialogCancelled(false)
                        checkGPS(event)
                    }}
                    open={folderValidationSummary && isFolderValidationDialogOpen}
                />
            }
            {isGPSCheckDialogOpen &&
                <GPSCheckDialog
                    onCancel={() => {
                        setIsGPSCheckDialogOpen(false)
                    }}
                    onConfirm={() => {
                        setIsGPSCheckDialogOpen(false)
                        checkCrossStrip()
                    }}
                    parts={missingGPSStartFilenameList}
                    open={isGPSCheckDialogOpen} 
                />
            }
            {isMissingFlightPlanCSDialogOpen          // add this line to make sure this dialog has correct z-index
                && <ConfirmationDialog 
                    title="No Cross-Strips Found!"
                    confirmLabel="OK" 
                    onConfirm={() => {
                        setIsMissingFlightPlanCSDialogOpen(false)
                        checkFmsLogMismatchProject()
                    }}
                    onCancel={() => {
                        setIsMissingFlightPlanCSDialogOpen(false)
                    }}
                    open={isMissingFlightPlanCSDialogOpen}
                >
                    <p>No Cross-Strips found. Remember, these KMZ must be in the root folder. Select <span style={{ fontWeight: 'bold' }}>OK</span> to confirm this is the Flightplan KMZ downloaded from Sharepoint and allow upload to commence</p>
                </ConfirmationDialog>
            }
            {isFmsLogMismatchProjectAlertDialogOpen          // add this line to make sure this dialog has correct z-index
                && <ConfirmationDialog 
                    title="Check FMS Log Files"
                    confirmLabel="OK" 
                    onConfirm={() => {
                        setIsFmsLogMismatchProjectAlertDialogOpen(false)
                        checkFmsLogMismatchTime()
                    }}
                    onCancel={() => {
                        setIsFmsLogMismatchProjectAlertDialogOpen(false)
                    }}
                    open={isFmsLogMismatchProjectAlertDialogOpen}
                >
                    <span style={{ lineHeight: 2 }}>
                        <h4>Please check the included FMS log files for this flight:</h4>
                        <ul>
                            {(!!missingProjectIdList.length)
                                && <>
                                    <li>Some expected projects in this flight, weren't included in any of the logs:</li>
                                    <ul>
                                        {React.Children.toArray(
                                            missingProjectIdList.map((projectId, index) => (
                                                <li key={index}><strong>{projectId}</strong></li>)
                                            )
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!fmsLogFilenameListWithNoProjectId.length)
                                && <>
                                    <li>The following files may be relevant but don't reference any of the projects expected for this flight:</li>
                                    <ul>
                                        {React.Children.toArray(
                                            fmsLogFilenameListWithNoProjectId.map((filename, index) => (
                                                <li key={index}><strong>{filename}</strong></li>)
                                            )
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!fmsLogFilenameListWithUnexpectedProjectId.length)
                                && <>
                                    <li>The following files may be relevant but include projects that weren't expected for this flight:</li>
                                    <ul>
                                        {React.Children.toArray(
                                            fmsLogFilenameListWithUnexpectedProjectId.map((filename, index) => (
                                                <li key={index}><strong>{filename}</strong></li>)
                                            )
                                        )}
                                    </ul>
                                </>
                            }
                        </ul>
                        <p>Proceed with the upload after you have confirmed the included files are correct and complete. If some were missing, please copy these to the relevant location, refresh the browser and select the files again.</p>
                    </span>
                </ConfirmationDialog>
            }
            {isFmsLogMismatchTimeAlertDialogOpen          // add this line to make sure this dialog has correct z-index
                && <ConfirmationDialog 
                    title="Check FMS Log Files"
                    confirmLabel="OK" 
                    onConfirm={() => {
                        setIsFmsLogMismatchTimeAlertDialogOpen(false)
                        checkLazHeader()
                    }}
                    onCancel={() => {
                        setIsFmsLogMismatchTimeAlertDialogOpen(false)
                    }}
                    open={isFmsLogMismatchTimeAlertDialogOpen}
                >
                    <span style={{ lineHeight: 2 }}>
                        <p>
                            The GPS track times of the FMS logs do not overlap with the flight's wheels-up and wheels-down times.
                            It might be because unnecessary or incorrect FMS logs are attempting to be uploaded. Mismatched FMS logs may cause processing failure.
                        </p>
                        <p>Please check the included FMS log files for this flight:</p>
                        <ul>
                            <ul>
                                {React.Children.toArray(
                                    fmsLogFilenameList.map((filename, index) => (
                                        <li key={index}><strong>{filename}</strong></li>)
                                    )
                                )}
                            </ul>
                        </ul>
                        <h4>The data will be processed without splitting while using the mismatched FMS logs.</h4>
                    </span>
                </ConfirmationDialog>
            }
            {(isLazHeaderAlertDialogOpen && lazFilenameListWithInvalidHeader.length)
                && <Dialog 
                    disableBackdropClick 
                    disableEscapeKeyDown 
                    open={isLazHeaderAlertDialogOpen}
                >
                    <DialogTitle>Invalid laz files</DialogTitle>
                    <DialogContent>
                        <span style={{ lineHeight: 2 }}>
                            <p>Unable to read LAZ headers from the following files, which may be due to file corruption or the wrong file extention.</p>
                            <ul>
                                {React.Children.toArray(
                                    lazFilenameListWithInvalidHeader.slice(0, 3).map((filename, index) => (
                                        <li key={index}><strong>{filename}</strong></li>
                                    )))
                                }
                                {lazFilenameListWithInvalidHeader.length > 3 
                                    && <li><strong>...</strong></li>
                                }
                            </ul>
                            <p>Please check the data and remove all invalid laz files. The processing will not be executed until all laz files meet the requirement.</p>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            component="span" 
                            color="primary" 
                            style={{ backgroundColor: '#ff0000' }} 
                            onClick={() => setIsLazHeaderAlertDialogOpen(false)}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {(isLazRgbnAlertDialogOpen && lazFilenamelistWithoutRGBN.length)
                && <Dialog 
                    disableBackdropClick 
                    disableEscapeKeyDown 
                    open={isLazRgbnAlertDialogOpen}
                >
                    <DialogTitle>Invalid laz files</DialogTitle>
                    <DialogContent>
                        <span style={{ lineHeight: 2 }}>
                            <p>The current laz files do not contain RGBN data, which is required by the selected processing template.</p>
                            <ul>
                                {React.Children.toArray(
                                    lazFilenamelistWithoutRGBN.slice(0, 3).map((filename, index) => (
                                        <li key={index}><strong>{filename}</strong></li>
                                    )))
                                }
                                {lazFilenamelistWithoutRGBN.length > 3 
                                    && <li><strong>...</strong></li>
                                }
                            </ul>
                            <p>Please check the data and remove all invalid laz files. The processing will not be executed until all laz files meet the requirement.</p>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            component="span" 
                            color="primary" 
                            style={{ backgroundColor: '#ff0000' }} 
                            onClick={() => setIsLazRgbnAlertDialogOpen(false)}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {isBlankFileAlertDialogOpen          // add this line to make sure this dialog has correct z-index
                && <ConfirmationDialog 
                    title="Check Zero-byte Files"
                    confirmLabel="OK" 
                    onConfirm={() => {
                        setIsBlankFileAlertDialogOpen(false)
                        setIsFolderValidationDialogOpen(false)
                        updateUploadStatus(transition({ type: VALID_FSM_EVENT_TYPE.VALIDATION_SUCCEED }).status)
                    }}
                    onCancel={() => {
                        setIsBlankFileAlertDialogOpen(false)
                    }}
                    open={isBlankFileAlertDialogOpen}
                >
                    <span style={{ lineHeight: 2 }}>
                        <p><strong>Zero-byte files found.</strong> Files with a size of 0 bytes are invalid, and the following files will be excluded from the upload:</p>
                        <ul>
                            {React.Children.toArray(
                                blankFileList.map((filename, index) => (
                                    <li key={index}><strong>{filename}</strong></li>)
                                )
                            )}
                        </ul>
                        <p>Proceed with the upload if you are satisfied these files are not needed. If however these are necessary, please review to ensure they are not corrupted and have been copied in full, then refresh the browser and select the files again.</p>
                    </span>
                </ConfirmationDialog>
            }
            <Dialog
                open={isSyncStatusDialogOpen}
                PaperProps={{
                    style: {
                    // backgroundColor: 'transparent',
                    backgroundColor: '#808080e6',
                    boxShadow: 'none',
                    // boxShadow: '0px 0px 135px 808080e6',
                    minHeight: '150px',
                    minWidth: '300px',
                    borderRadius: 10,
                    },
                }}
            >
                <Grid container direction="column" alignItems="center" justify="center" className='pt-3'>
                    <CircularProgress />
                    <div className='pt-5' >Sync status with server...</div>
                </Grid>
            </Dialog>
            <Dialog
                open={uploaderStore.status === VALID_STATUS.UPLOAD_QUEUED && uploadQueueIndex > 0}
                PaperProps={{
                    style: {
                    // backgroundColor: 'transparent',
                    backgroundColor: '#808080e6',
                    // boxShadow: 'none',
                    boxShadow: '0px 0px 135px 808080e6',
                    minHeight: '400px',
                    minWidth: '550px',
                    borderRadius: 10,
                    },
                }}
            >
                <Grid container direction="column" alignItems="center" justify="center" className='pt-3'>
                    <div className='pt-2 pb-4' style={{ fontSize: 30, fontWeight: 'bolder' }} >UPLOAD QUEUED</div>
                    <div className='pb-4' style={{ fontSize: 35, fontWeight: 'bolder', color: 'red' }}>DO NOT CLOSE BROWSER</div>
                    <CircularProgress />
                    <div className='pt-5 pb-4' style={{ fontSize: 25 }}>{uploadQueueIndex} upload jobs before you in the queue </div>
                </Grid>
                <DialogActions>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => setIsUploadQueuingDialogOpen(true)}
                        >
                            Quit the Queue
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            {isUploadQueuingDialogOpen          // add this line to make sure this dialog has correct z-index
                && <ConfirmationDialog 
                    title="Quit Upload Queue Confirmation"
                    confirmLabel="OK" 
                    onConfirm={() => handleQuitUploadQueue()}
                    onCancel={() => {
                        setIsUploadQueuingDialogOpen(false)
                    }}
                    open={isUploadQueuingDialogOpen}
                >
                    <p>Confirm to quit the upload queue?</p>
                </ConfirmationDialog>}
            <CancellationDialog 
                open={isUploadCancelDialogOpen} 
                onConfirm={handleUploadCancel} 
                onCancel={() => setIsUploadCancelDialogOpen(false)} 
            />
            <UploadConfirmationDialog 
                title="Update Existing Upload?"
                onCancel={() => setIsUpdateExistingDataDialogOpen(false)}
                confirmLabel="UPDATE"
                onSkipToFileSelection={handleOnSkipToFileSelection}
                open={isUpdateExistingDataDialogOpen}
                currentUploadType={[(uploaderStore.uploadMeta as UploadMeta).uploadType]}
            >
                <p>Are you sure you want to update the existing data?</p>
                <p>You may <span style={{ fontWeight: 'bold' }}>ADD</span> to or <span style={{ fontWeight: 'bold' }}>OVERWRITE</span> parts of the existing data.</p>
                <p>Any overwritten data can not be recovered later.</p>
                <p>On confirmation, select the parent folder and then select the individual files.</p>
            </UploadConfirmationDialog>
        </>
    )
}

// export default UploadPanel
const mapStateToProps = (rootState: any) => {
    let tokenPayload = rootState.token ? JSON.parse(atob(rootState.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''
    return ({
        authToken: rootState.token,
        user,
        uploaderStore: rootState.uploader as UploaderStore
    })
}

const mapDispatchToProps = { 
    updateUploadStatus, handleUploadMetadata, addUploadMetadataList, addUploadTaskList,
    handleUpdateUploadStatusToDB, addUploadOngoingTask, updateUploadOngoingTaskStatus,
    updateUploadOngoingTaskProgress, handleUploadBlob, completeUploadOngoingTask,
    withdrawAllUploadOngingTasks, updateGlobalUploadProgress, handleFetchUploadProgress,
    handleCheckUploadProgress, filterRemainingUploadTask, cancelUploadTask,
    handleFetchUploadRecord, handleAccessCurrentReduxAuthState, 
    handleAccessCurrentReduxUploaderState, clearUploaderStore, renewToken,
    handleUpdateUploadHeartbeat, handleCheckConcurrentUpload
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type UploadPanelFromRedux = ConnectedProps<typeof connector>

const ConnectedUploadPanel = connector(UploadPanel)

export default ConnectedUploadPanel