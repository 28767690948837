import axios from 'axios';
import Config from '../app/Config.json'

const BASE_URI = Config.IP_URI

export function processingListHasError(bool) {
    return {
        type: 'PROCESSING_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function processingListLoading(bool) {
    return {
        type: 'PROCESSING_LIST_LOADING',
        isLoading: bool
    };
}

export function processingListSuccess(processing) {
    return {
        type: 'PROCESSING_LIST_SUCCESS',
        processing
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
export function processingQueryParams(payload) {
    return {
        type: 'PROCESSING_QUERY_PARAMS',
        payload
    }
}

export function resetProcessingState() {
    return (dispatch) => {
        dispatch(processingListLoading(false));
        dispatch(processingListHasError(false));
        dispatch(processingQueryParams({}));
        dispatch(processingListSuccess({}));
    }
}

export function processingFetchList(token) {
    return (dispatch, getState) => {

        dispatch(processingListLoading(true));
        const state = getState(), { page, pageSize, ...queryParams } = state.processingQueryParams
        token = token || state.token

        // const server = BASE_URI + 'processing' + `?page=${page}&pageSize=${pageSize}`,headers = { authorization: 'Bearer ' + token };
        // const server = BASE_URI + 'processing' + `?include=ProjectID+project+ProjectID+Project,FlightID+flight+FlightID+Flights&page=${page}&pageSize=${pageSize}`, headers = { authorization: 'Bearer ' + token };
        // const server = BASE_URI + 'processing' + `?page=${page}&pageSize=${pageSize}`, headers = { authorization: 'Bearer ' + token };
        const server = BASE_URI + 'processing' + `?page=${page}&pageSize=${pageSize}&include=ProjectID project ProjectID Project`, headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(processingListLoading(false));
            //console.log(response)
            const total = parseInt(response.headers['x-total-count'])
            dispatch(processingQueryParams({ total }));
            return response;
        }).then((response) => dispatch(processingListSuccess(response.data))).catch(() => {
            dispatch(processingListLoading(false));
            dispatch(processingListHasError(true))
        });
    };
}

export function paramsListHasError(bool) {
    return {
        type: 'PARAMS_LIST_HAS_ERROR',
        hasError: bool
    };
}

export function paramsListLoading(bool) {
    return {
        type: 'PARAMS_LIST_LOADING',
        isLoading: bool
    };
}

export function paramsListSuccess(params) {
    return {
        type: 'PARAMS_LIST_SUCCESS',
        params
    };
}
/**
 * 
 * @param {*} page, total, pageSize 
 */
export function paramsQueryParams(payload) {
    return {
        type: 'PARAMS_QUERY_PARAMS',
        payload
    }
}

export function paramsFetchList(token) {
    return (dispatch, getState) => {

        dispatch(paramsListLoading(true));
        const state = getState(), { page, pageSize, ...queryParams } = state.paramsQueryParams
        token = token || state.token

        const server = BASE_URI + 'calibration' + `?page=${page}&pageSize=${pageSize}`,
            headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(paramsListLoading(false));
            //console.log(response)
            const total = parseInt(response.headers['x-total-count'])
            dispatch(paramsQueryParams({ total }));
            return response;
        }).then((response) => dispatch(paramsListSuccess(response.data))).catch(() => {
            dispatch(paramsListLoading(false));
            dispatch(paramsListHasError(true))
        });
    };
}

export function imageryParamsFetchList(token) {
    return (dispatch, getState) => {

        dispatch(paramsListLoading(true));
        const state = getState(), { page, pageSize, ...queryParams } = state.paramsQueryParams
        token = token || state.token

        const server = BASE_URI + 'imagery-calibration' + `?page=${page}&pageSize=${pageSize}`,
            headers = { authorization: 'Bearer ' + token };

        axios.get(server, { params: queryParams.params, headers }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(paramsListLoading(false));
            //console.log(response)
            const total = parseInt(response.headers['x-total-count'])
            dispatch(paramsQueryParams({ total }));
            return response;
        }).then((response) => dispatch(paramsListSuccess(response.data))).catch(() => {
            dispatch(paramsListLoading(false));
            dispatch(paramsListHasError(true))
        });
    };
}

//CREATE
export function paramsUpdateHasError(bool) {
    return {
        type: 'PARAMS_UPDATE_HAS_ERROR',
        hasError: bool
    };
}

export function paramsUpdateLoading(bool) {
    return {
        type: 'PARAMS_UPDATE_LOADING',
        isLoading: bool
    };
}

export function paramsUpdateSuccess(params) {
    return {
        type: 'PARAMS_UPDATE_SUCCESS',
        params
    };
}

// export function paramsUpdate(token,json_doc) {
//     return (dispatch, getState) => {

//         dispatch(paramsUpdateLoading(true));

//         const server = BASE_URI + 'calibration',
//             headers = { authorization: 'Bearer ' + token };

//         axios.post(server, { headers }).then((response) => {
//             if (response.status !== 200) {
//                 throw Error(response.statusText);
//             }
//             dispatch(paramsUpdateLoading(false));
//             return response;
//         }).then((response) => dispatch(paramsUpdateSuccess(response.data))).catch(() => {
//             dispatch(paramsUpdatetLoading(false));
//             dispatch(paramsUpdateHasError(true))
//         });
//     };
// }

export function resetState() {
    return (dispatch) => {
        dispatch(paramsUpdateSuccess({
            SensorName: '',
            Aircraft: '',
            PriGNSSLeverX: '',
            PriGNSSLeverY: '',
            PriGNSSLeverZ: '',
            RefToIMULeverX: '',
            RefToIMULeverY: '',
            RefToIMULeverZ: '',
            RefToIMUMountingAnglX: '',
            RefToIMUMountingAnglY: '',
            RefToIMUMountingAnglZ: '',
            SurveyDate: '',
            AircraftType: '',
            LMSInstrumentRES: '',
            LMSInstrumentTBL: '',
            LMSInstrumentLCP: '',
            ScheduledExpiryDate: '',
            PriGNSSLeverSD: "SD10cm",
            IsActive: true,
            //added below for imagery params
            CameraLeverArmX: '',
            CameraLeverArmY: '',
            CameraLeverArmZ: '',
            CameraBoresiteAngleTx: '',
            CameraBoresiteAngleTy: '',
            CameraBoresiteAngleTz: '',
            KappaCardinal: ''
        }));
    }
}

// POST
export function imageryParamsCreate(token, /*returnResult = false,*/ jsonData = null) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));
        ///let paramString = returnResult ? '?returnResult=true' : '';

        let requestObject = {
            method: 'post',
            url: BASE_URI + 'imagery-calibration',// + paramString,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            // data: jsonData
        };

        if (jsonData !== null) {
            requestObject.data = jsonData;
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));
                return response;
            })
            .then((response) => {
                if (response.data.result)
                    dispatch(paramsUpdateSuccess(response.data.result));
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}
//PUT
export function imageryParamsUpdate(token, id, jsonData = null) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));
        ///let paramString = returnResult ? '?returnResult=true' : '';

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'imagery-calibration/' + id,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            // data: jsonData
        };

        if (jsonData !== null) {
            requestObject.data = jsonData;
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));

                return [response, requestObject];
            })
            .then((responseArray) => {
                //a bit of a hack because I just want to resend the data that was updated, not the response
                if (responseArray[0].status === 200) {
                    dispatch(paramsUpdateSuccess(responseArray[1].data));
                    dispatch(resetState());//this is here because sometimes there is a race condition on update.... hacky I know. One day I'll learn redux properly.
                }
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}


// POST
export function paramsCreate(token, /*returnResult = false,*/ jsonData = null) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));
        ///let paramString = returnResult ? '?returnResult=true' : '';

        let requestObject = {
            method: 'post',
            url: BASE_URI + 'calibration',// + paramString,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            // data: jsonData
        };

        if (jsonData !== null) {
            requestObject.data = jsonData;
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));
                return response;
            })
            .then((response) => {
                if (response.data.result)
                    dispatch(paramsUpdateSuccess(response.data.result));
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}

//PUT
export function paramsUpdate(token, id, jsonData = null) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));
        ///let paramString = returnResult ? '?returnResult=true' : '';

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'calibration/' + id,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            // data: jsonData
        };

        if (jsonData !== null) {
            requestObject.data = jsonData;
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));

                return [response, requestObject];
            })
            .then((responseArray) => {
                //a bit of a hack because I just want to resend the data that was updated, not the response
                if (responseArray[0].status === 200) {
                    dispatch(paramsUpdateSuccess(responseArray[1].data));
                    dispatch(resetState());//this is here because sometimes there is a race condition on update.... hacky I know. One day I'll learn redux properly.
                }
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}

//GET
export function downloadInstrumentFiles(token, id) {
    return (dispatch) => {
        dispatch(calibrationDownloadLoading(true));

        axios.get(
            BASE_URI + 'calibration/' + id + '/instrument_files/download',
            {
                headers: {
                    authorization: 'Bearer ' + token,
                    Accept: 'application/zip'
                },
                responseType: 'blob'
            }
        ).then((response) => {

            dispatch(calibrationDownloadLoading(false));

            console.log('...name', response.headers["x-name"])
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["x-name"]);
            document.body.appendChild(link);
            link.click();
        });
        // .then((response) => {
        //     if (response.status !== 200) {
        //         throw Error(response.statusText);
        //     }

        //     dispatch(paramsUpdateLoading(false));
        //     return response;
        // })
        // .then((response) => {
        //     dispatch(paramsUpdateSuccess(response.data))
        // })
        // .catch(() => {
        //     dispatch(paramsUpdateLoading(false));
        //     dispatch(paramsUpdateHasError(true));
        // });
    };
}

export function calibrationDownloadLoading(bool) {
    return {
        type: 'CALIBRATION_DOWNLOAD_LOADING',
        isLoading: bool
    };
}

// export function calibrationDownloadSuccess(bool) {
//     return {
//         type: 'CALIBRATION_DOWNLOAD_SUCCESS',
//         isSuccessful: bool
//     };
// }


//GET
export function paramsFetchItem(token, id) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));

        axios.get(
            BASE_URI + 'calibration/' + id,
            {
                headers: { authorization: 'Bearer ' + token }
            }
        )
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(paramsUpdateSuccess(response.data))
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}

//GET
export function imageryParamsFetchItem(token, id) {
    return (dispatch) => {
        dispatch(paramsUpdateLoading(true));

        axios.get(
            BASE_URI + 'imagery-calibration/' + id,
            {
                headers: { authorization: 'Bearer ' + token }
            }
        )
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(paramsUpdateLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(paramsUpdateSuccess(response.data))
            })
            .catch(() => {
                dispatch(paramsUpdateLoading(false));
                dispatch(paramsUpdateHasError(true));
            });
    };
}

//PROCESSING SUBMIT
export function processingResubmitHasError(bool) {
    return {
        type: 'PROCESSING_RESUBMIT_HAS_ERROR',
        hasError: bool
    };
}

export function processingResubmitLoading(bool) {
    return {
        type: 'PROCESSING_RESUBMIT_LOADING',
        isLoading: bool
    };
}

export function processingResubmitLoadingWithId(isLoading, processingId) {
    return {
        type: 'PROCESSING_RESUBMIT_LOADING_W_ID',
        isLoading,
        processingId
    };
}

export function processingResubmitSuccess(bool) {
    return {
        type: 'PROCESSING_RESUBMIT_SUCCESS',
        isSuccessful: bool
    };
}
// PUT
//TODO: update with appropriate actions!
export function resubmitProcessing(token, jsonData, id) {
    return (dispatch) => {
        dispatch(processingResubmitLoading(true));
        dispatch(processingResubmitLoadingWithId(true, id))

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'processing/' + id,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            data: jsonData
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingResubmitLoading(false));
                if (response.status === 200)
                    dispatch(processingResubmitSuccess(true));
                return response;
            })
            // .then((response) => {
            //     if (response.status === 200)
            //         dispatch(processingResubmitSuccess(true));
            // })
            .catch(() => {
                dispatch(processingResubmitLoading(false));
                dispatch(processingResubmitHasError(true));
            })
            .finally(() => {
                dispatch(processingResubmitLoadingWithId(false, id))
            });
    };
}

export function resubmitEOProcessing(token, id) {
    return (dispatch) => {
        dispatch(processingResubmitLoading(true));
        dispatch(processingResubmitLoadingWithId(true, id))

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'processing/' + id + '/manual_gps_eo_reprocess',
            headers: {
                "Authorization": 'Bearer ' + token
            },
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingResubmitLoading(false));
                if (response.status === 200)
                    dispatch(processingResubmitSuccess(true));
                return response;
            })
            // .then((response) => {
            //     if (response.status === 200)
            //         dispatch(processingResubmitSuccess(true));
            // })
            .catch(() => {
                dispatch(processingResubmitLoading(false));
                dispatch(processingResubmitHasError(true));
            })
            .finally(() => {
                dispatch(processingResubmitLoadingWithId(false, id))
            });
    };
}

//PROCESSING - LMS RESUBMIT
export function processingLMSResubmitHasError(bool) {
    return {
        type: 'PROCESSING_LMS_RESUBMIT_HAS_ERROR',
        hasError: bool
    };
}

export function processingLMSResubmitLoading(bool) {
    return {
        type: 'PROCESSING_LMS_RESUBMIT_LOADING',
        isLoading: bool
    };
}

export function processingLMSResubmitLoadingWithId(isLoading, processingId) {
    return {
        type: 'PROCESSING_LMS_RESUBMIT_LOADING_W_ID',
        isLoading,
        processingId
    };
}

export function processingLMSResubmitSuccess(bool) {
    return {
        type: 'PROCESSING_LMS_RESUBMIT_SUCCESS',
        isSuccessful: bool
    };
}

export function resubmitLMSProcessing(token, id) {
    return (dispatch) => {
        dispatch(processingLMSResubmitLoading(true));
        dispatch(processingLMSResubmitLoadingWithId(true, id))

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'processing/' + id + '/lms/submit',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingLMSResubmitLoading(false));
                if (response.status === 200)
                    dispatch(processingLMSResubmitSuccess(true));
                return response;
            })
            // .then((response) => {
            //     if (response.status === 200)
            //         dispatch(processingResubmitSuccess(true));
            // })
            .catch(() => {
                dispatch(processingLMSResubmitLoading(false));
                dispatch(processingLMSResubmitHasError(true));
            })
            .finally(() => {
                dispatch(processingLMSResubmitLoadingWithId(false, id))
            });
    };
}

//PROCESSING - CLUSTER RESUBMIT
export function processingClusterResubmitHasError(bool) {
    return {
        type: 'PROCESSING_CLUSTER_RESUBMIT_HAS_ERROR',
        hasError: bool
    };
}

export function processingClusterResubmitLoading(bool) {
    return {
        type: 'PROCESSING_CLUSTER_RESUBMIT_LOADING',
        isLoading: bool
    };
}

export function processingClusterResubmitLoadingWithId(isLoading, processingId) {
    return {
        type: 'PROCESSING_CLUSTER_RESUBMIT_LOADING_W_ID',
        isLoading,
        processingId
    };
}

export function processingClusterResubmitSuccess(bool) {
    return {
        type: 'PROCESSING_CLUSTER_RESUBMIT_SUCCESS',
        isSuccessful: bool
    };
}

// Invoked when LMS is manually processed
export function firstSubmitClusterProcessing(token, id) {
    return (dispatch) => {
        dispatch(processingClusterResubmitLoading(true));
        dispatch(processingClusterResubmitLoadingWithId(true, id))

        let requestObject = {
            method: 'get',
            url: BASE_URI + 'processing/' + id + '/cluster_pipeline/first_submit',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingClusterResubmitLoading(false));
                if (response.status === 200)
                    dispatch(processingClusterResubmitSuccess(true));
                return response;
            })
            // .then((response) => {
            //     if (response.status === 200)
            //         dispatch(processingResubmitSuccess(true));
            // })
            .catch(() => {
                dispatch(processingClusterResubmitLoading(false));
                dispatch(processingClusterResubmitHasError(true));
            })
            .finally(() => {
                dispatch(processingClusterResubmitLoadingWithId(false, id))
            });
    };
}

export function resubmitClusterProcessing(token, id, clusterName) {
    return (dispatch) => {
        dispatch(processingClusterResubmitLoading(true));
        dispatch(processingClusterResubmitLoadingWithId(true, id))

        let requestObject = {
            method: 'put',
            url: BASE_URI + 'processing/' + id + '/' + clusterName + '/submit',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingClusterResubmitLoading(false));
                if (response.status === 200)
                    dispatch(processingClusterResubmitSuccess(true));
                return response;
            })
            // .then((response) => {
            //     if (response.status === 200)
            //         dispatch(processingResubmitSuccess(true));
            // })
            .catch(() => {
                dispatch(processingClusterResubmitLoading(false));
                dispatch(processingClusterResubmitHasError(true));
            })
            .finally(() => {
                dispatch(processingClusterResubmitLoadingWithId(false, id))
            });
    };
}

export function processingUpdate(token, id, jsonData = null, clusterName = null) {
    return (dispatch) => {
        dispatch(processingUpdateLoading(true));

        let url = clusterName !== null ? BASE_URI + 'processing/' + id + '/'+clusterName : BASE_URI + 'processing/' + id + '/update';// '/update' is inconsistent

        let requestObject = {
            method: 'put',
            url: url,
            headers: {
                "Authorization": 'Bearer ' + token
            },
            // data: jsonData
        };

        if (jsonData !== null) {
            requestObject.data = jsonData;
        }

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(processingUpdateLoading(false));

                return [response, requestObject];
            })
            .then((responseArray) => {
                //a bit of a hack because I just want to resend the data that was updated, not the response
                if (responseArray[0].status === 200) {
                    dispatch(processingUpdateSuccess(responseArray[1].data));
                    // dispatch(resetState());//this is here because sometimes there is a race condition on update.... hacky I know. One day I'll learn redux properly.
                }
            })
            .catch(() => {
                dispatch(processingUpdateLoading(false));
                dispatch(processingUpdateHasError(true));
            });
    };
}

export function processingUpdateHasError(bool) {
    return {
        type: 'PROCESSING_UPDATE_HAS_ERROR',
        hasError: bool
    };
}

export function processingUpdateLoading(bool) {
    return {
        type: 'PROCESSING_UPDATE_LOADING',
        isLoading: bool
    };
}

export function processingUpdateSuccess(processing) {
    return {
        type: 'PROCESSING_UPDATE_SUCCESS',
        processing
    };
}

//download auth
export function authoriseDownload(guid,sensorId,projectId,aoiNum) {
    return (dispatch, getState) => {
        const { token } = getState();
        dispatch(downloadAuthLoading(true));
        let requestObject = {
            method: 'post',
            url: BASE_URI + 'flightline_kmz',
            headers: {
                "Authorization": 'Bearer ' + token
            },
            data: {
                "guid":guid,
                "sensorId":sensorId,
                "projectId":projectId,
                "aoiNum":aoiNum
            }
        };

        axios(requestObject)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                dispatch(downloadAuthLoading(false));

                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(downloadAuthSuccess(response.data.url));
                }
            })
            .catch(() => {
                dispatch(downloadAuthLoading(false));
                dispatch(downloadAuthHasError(true));
            });
    };
}

export function downloadAuthHasError(bool) {
    return {
        type: 'DOWNLOAD_AUTH_HAS_ERROR',
        hasError: bool
    };
}

export function downloadAuthLoading(bool) {
    return {
        type: 'DOWNLOAD_AUTH_LOADING',
        isLoading: bool
    };
}

export function downloadAuthSuccess(url) {
    return {
        type: 'DOWNLOAD_AUTH_SUCCESS',
        url
    };
}

// Fetch kmz file list
export function fetchKmzFilePresignedUrlListLoading(bool) {
    return {
        type: 'FETCH_KMZ_FILE_URL_LIST_LOADING',
        isLoading: bool
    };
}

export function fetchKmzFilePresignedUrlListSuccess(fileList) {
    return {
        type: 'FETCH_KMZ_FILE_URL_LIST_SUCCESS',
        fileList
    };
}

export function fetchKmzFilePresignedUrlListHasError(bool) {
    return {
        type: 'FETCH_KMZ_FILE_URL_LIST_HAS_ERROR',
        hasError: bool
    };
}

// list all kmz files
const fetchKmzFilePresignedUrlListPromise = (token, flightId, projectId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: BASE_URI + 'flights/' + flightId + '/list_kmz/' + projectId,
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data)
        } catch(error) {
            return reject(error)
        }
    })
}

export const fetchKmzFilePresignedUrlList = (flightId, projectId) => {
    return async (dispatch, getState) => {
        const { token } = getState()
        dispatch(fetchKmzFilePresignedUrlListLoading(true));
        try {
            const fetchKmzFilePresignedUrlListResponse = await fetchKmzFilePresignedUrlListPromise(token, flightId, projectId)
            dispatch(fetchKmzFilePresignedUrlListLoading(false))
            dispatch(fetchKmzFilePresignedUrlListSuccess(fetchKmzFilePresignedUrlListResponse || {}))
            return fetchKmzFilePresignedUrlListResponse
        } catch(error) {
            console.log(`error: ${error}`)
            dispatch(fetchKmzFilePresignedUrlListLoading(false))
            dispatch(fetchKmzFilePresignedUrlListHasError(true))
            return {}
        }
    }
}

export function resetDownloadAuthState() {
    return (dispatch) => {
        dispatch(downloadAuthHasError(false));
        dispatch(downloadAuthLoading(false));
        dispatch(downloadAuthSuccess(null));
        dispatch(fetchKmzFilePresignedUrlListHasError(false));
        dispatch(fetchKmzFilePresignedUrlListLoading(false));
        dispatch(fetchKmzFilePresignedUrlListSuccess(null));
    }
}